import { ChangeEvent, MouseEvent } from 'react';
import { Trans } from 'react-i18next';
import { Paragraph } from '@library/components/atoms';
import clsx from 'clsx';
import { t } from 'i18next';

import { Heading } from '@shared/components/Heading';
import { Icon } from '@shared/components/Icon';
import { Input } from '@shared/components/Input/Input';
import { Spinner } from '@shared/components/Spinner';
import { DivideBuyInstalment } from '@shared/data/types';

interface CalculatorProps {
  activeTermData?: DivideBuyInstalment;
  data?: DivideBuyInstalment[];
  handleSetLoanAmount: (event: ChangeEvent<HTMLInputElement>) => void;
  handleSetSelectedTerm: (event: MouseEvent<HTMLButtonElement>) => void;
  noAvailableInstalments?: boolean;
  selectedTerm?: number | null;
  loanAmount: string;
  error?: string;
  isLoading?: boolean;
  termLengths: number[];
}
export const FinanceCalculator = ({
  activeTermData,
  data,
  handleSetLoanAmount,
  handleSetSelectedTerm,
  selectedTerm,
  noAvailableInstalments,
  loanAmount,
  error,
  isLoading,
  termLengths,
}: CalculatorProps) => {
  return (
    <div className="col-span-12 px-0 sm:px-8 xl:px-0 ">
      <div
        id="finance-calculator"
        className="max-w-[1200px] m-auto mt-4 bg-blue-50 min-h-[600px] p-[2.75rem] sm:p-[3.75rem] rounded-3xl"
      >
        <div className="md:grid md:grid-cols-2 gap-10">
          <div className="flex flex-col justify-between h-full lg:min-h-[37.5rem] max-w-[26.5rem]">
            <Heading variant="h2">{t('patient.financePromoPage.calculator.heading')}</Heading>
            <Paragraph size="medium" className="hidden lg:block !text-[#5E718D]">
              {t('patient.financePromoPage.calculator.tradingInfo')}
            </Paragraph>
          </div>
          <div>
            <div className="lg:max-w-[420px] h-full lg:min-h-[37.5rem]">
              <Paragraph size="medium" className="!text-[#5E718D] mb-2">
                {t('patient.financePromoPage.calculator.financeAmount')}
              </Paragraph>
              <Input
                data-testid="calculator-amount-input"
                onChange={handleSetLoanAmount}
                type="number"
                defaultValue={loanAmount}
              />
              {error && !isLoading && (
                <div className="flex gap-2 items-start mt-2.5">
                  <Icon className="stroke-[#F2594F] stroke-2" name="info" size={20} />
                  <Paragraph size="medium" className="text-[#F2594F]">
                    {error}
                  </Paragraph>
                </div>
              )}
              {isLoading ? (
                <Spinner />
              ) : (
                <>
                  <Paragraph size="medium" className="!text-[#5E718D] mt-8">
                    {t('patient.financePromoPage.calculator.monthlyPayments')}
                  </Paragraph>
                  <div className="flex gap-2 mt-2 mb-8 justify-left">
                    {termLengths.map((term) => {
                      const ternmUnavailable = !data?.find((item) => item.term_length === term)?.available;
                      return (
                        <button
                          data-testid={`calculator-term-button-${term}`}
                          key={term}
                          value={term}
                          onClick={handleSetSelectedTerm}
                          className={clsx(
                            'p-[14px_25px] md:p-[14px_30px] border rounded-[3.25rem]',
                            term === selectedTerm && !noAvailableInstalments
                              ? '!bg-primary-main !text-white'
                              : 'bg-white',
                            ternmUnavailable ? 'disabled:bg-transparent text-[#8897AE]' : '',
                          )}
                          disabled={ternmUnavailable}
                        >
                          {term}
                        </button>
                      );
                    })}
                  </div>
                  {activeTermData && (
                    <>
                      <Paragraph size="medium" className="!text-[#5E718D] mb-2">
                        {t('patient.financePromoPage.calculator.interest')}
                      </Paragraph>
                      <Paragraph size="large" className="mb-16">{`${
                        activeTermData.type === 'ifc' ? 'Interest-Free 0%' : `${activeTermData.interest_rate}% APR`
                      }`}</Paragraph>
                      <Paragraph size="large">
                        <Trans
                          i18nKey="patient.financePromoPage.calculator.repayment"
                          components={{ bold: <strong /> }}
                          values={activeTermData}
                        />
                        <br />
                        <br />
                        <Trans
                          i18nKey="patient.financePromoPage.calculator.totalAmountRepayable"
                          values={activeTermData}
                        />
                      </Paragraph>
                    </>
                  )}

                  {activeTermData && (
                    <>
                      <Paragraph size="medium" className="mt-8 mb-4 lg:mb-0 !text-[#5E718D]">
                        <>
                          {activeTermData.type === 'ifc'
                            ? t('patient.treatmentPlanPage.paymentPage.instalments.unregulated.title')
                            : t('patient.treatmentPlanPage.paymentPage.instalments.regulated.title')}
                          &nbsp;
                          {activeTermData.type === 'ifc'
                            ? t('patient.treatmentPlanPage.paymentPage.instalments.unregulated.text', {
                                ...activeTermData,
                              })
                            : t('patient.treatmentPlanPage.paymentPage.instalments.regulated.text', {
                                ...activeTermData,
                              })}
                        </>
                      </Paragraph>
                      <Paragraph size="medium" className="block lg:hidden !text-[#5E718D]">
                        {t('patient.financePromoPage.calculator.tradingInfo')}
                      </Paragraph>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
