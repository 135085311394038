import { SVGAttributes } from 'react';

export type ColourType = 'bluebell' | 'duckegg' | 'navy' | 'oat' | 'pine' | 'sage' | 'white' | 'black';

export type LogoProps = {
  className?: string;
  colour: ColourType;
  width?: number;
  viewBox?: SVGAttributes<SVGSVGElement>['viewBox'];
};

export const colourLookup1 = {
  bluebell: 'fill-logo-bluebell-100',
  duckegg: 'fill-logo-duckegg-100',
  navy: 'fill-logo-navy-100',
  oat: 'fill-logo-oat-100',
  pine: 'fill-logo-pine-100',
  sage: 'fill-logo-sage-100',
  white: 'fill-logo-white-100',
  black: 'fill-logo-black',
};

export const colourLookup2 = {
  bluebell: 'fill-logo-bluebell-300',
  duckegg: 'fill-logo-duckegg-300',
  navy: 'fill-logo-navy-300',
  oat: 'fill-logo-oat-300',
  pine: 'fill-logo-pine-300',
  sage: 'fill-logo-sage-300',
  white: 'fill-logo-white-300',
  black: 'fill-logo-black',
};

export const LogoIcon = ({ colour, className, width = 48, viewBox = '0 0 320 320' }: LogoProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewBox} className={className} width={width}>
      <path
        className={colourLookup1[colour]}
        d="m227.88,69.49l-67.88,67.88-21.95-21.95c-24.87-24.87-65.39-25.82-90.58-1.27-25.6,24.94-25.8,65.91-.6,91.11l22.63,22.63,22.63,22.63,22.63-22.63,45.25-45.25,21.95,21.95c24.87,24.87,65.39,25.82,90.58,1.27,25.6-24.94,25.8-65.91.6-91.11l-45.25-45.25ZM92.12,205.25l-22.63-22.63c-12.5-12.5-12.5-32.76,0-45.25,12.5-12.5,32.76-12.5,45.25,0l22.63,22.63-45.25,45.25Zm112.67-23.1l-22.16-22.16,45.25-45.25,22.63,22.63c12.71,12.71,12.49,33.45-.64,45.88-12.66,11.99-32.75,11.23-45.08-1.1Z"
      />
      <rect
        className={colourLookup2[colour]}
        x="166.63"
        y="166.63"
        width="32"
        height="32"
        transform="translate(182.63 -75.65) rotate(45)"
      />
    </svg>
  );
};
