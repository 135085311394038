import { SetStateAction } from 'react';
import { Dialog } from '@headlessui/react';
import clsx from 'clsx';

import { Button } from '@shared/components/Button';
import { useMediaQuery } from '@shared/hooks';

import { Typography } from '../Typography';

export type ModalProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<SetStateAction<boolean>>;
  children: React.ReactNode;
  title?: string;
  description?: string;
  testId: string;
  /**
   * The modal panel padding size
   */
  paddingSize?: keyof typeof paddingLookup;
  showBreadcrumb?: boolean;
  size?: keyof typeof sizesLookup;
  /**
   * Classes to be applied to the modal wrapper
   */
  className?: string;
};

const sizesLookup = {
  md: 'w-3/5 m-auto',
  lg: 'w-full',
  xl: 'w-[35rem]',
};

const paddingLookup = {
  sm: 'p-2',
  md: 'p-4',
  xl: 'p-8',
};

export const Modal = ({
  isOpen,
  setIsOpen,
  children,
  title,
  description,
  testId,
  showBreadcrumb,
  paddingSize = 'xl',
  size = 'lg',
  className,
}: ModalProps) => {
  const isDesktop = useMediaQuery('min-width:768px');

  return (
    <Dialog
      open={isOpen}
      onClose={setIsOpen}
      aria-label={testId}
      as="div"
      className={clsx(
        'fixed inset-0 z-10 flex items-center justify-center pt-16 w-full m-auto',
        isDesktop && sizesLookup[size],
        className,
      )}
    >
      <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />
      <Dialog.Panel
        className={clsx(
          'relative bg-white overflow-y-auto w-full h-full xl:w-11/12 lg:h-[92%] md:rounded-3xl',
          paddingLookup[paddingSize],
        )}
      >
        <div className={clsx('flex', showBreadcrumb ? 'justify-between' : 'justify-end')}>
          <Button
            variant="primary"
            aria-label="Close modal"
            testId={`${testId}-close`}
            icon="x"
            size="small"
            onClick={() => setIsOpen(false)}
          />
        </div>
        {title && (
          <Dialog.Title>
            <Typography variant="h4" element="span" className="mb-2">
              {title}
            </Typography>
          </Dialog.Title>
        )}
        {description && (
          <Dialog.Description>
            <Typography variant="b16" element="span" className="text-grey-600">
              {description}
            </Typography>
          </Dialog.Description>
        )}
        <div className="pt-8">{children}</div>
      </Dialog.Panel>
    </Dialog>
  );
};
