import icons from './icons.json';

// NOTE: to add or update any icons simply:

// 1. grab the content inside an SVG element
// 2. minify the content https://www.svgminify.com/
// 3. escape the JSON https://www.freeformatter.com/json-escape.html

// The icons are sourced from https://feathericons.com/
// However you can also add other custom icons - for example the `brand` icon (just follow the process above)

export type IconNameType = keyof typeof icons;

type IconType = {
  name: IconNameType;
  size?: number;
  className?: string;
  viewBox?: string;
  onClick?: () => void;
  title?: string | false;
};

// NOTE: https://github.com/ianmiller347/feather-icons-react
export const Icon = ({
  name,
  size = 24,
  className = '',
  viewBox = '0 0 24 24',
  title,
  ...otherProps
}: IconType): React.ReactElement => {
  if (!name) {
    throw Error('Icon name must be provided.');
  }

  const iconMarkup = icons[name];

  if (!iconMarkup) {
    throw Error('Icon not found for icon name.');
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      width={size}
      height={size}
      viewBox={viewBox}
      fill="none"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      aria-hidden={title === undefined}
      className={className}
      {...otherProps}
    >
      {title !== false && <title>{title ?? `${name.replace('-', ' ')} icon`}</title>}
      <g dangerouslySetInnerHTML={{ __html: iconMarkup }} />
    </svg>
  );
};
