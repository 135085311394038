import { ChangeEvent } from 'react';

import { RadioButton } from '@shared/components/RadioButton';
import { Typography } from '@shared/components/Typography';
import { NotGoingAheadReason } from '@shared/data/types';

export const CheckoutNotGoingAheadOptions = ({
  onChange,
  hasPrice,
  financeEnabled,
  displayPricing,
}: {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  hasPrice: boolean;
  financeEnabled: boolean;
  displayPricing?: boolean;
}) => (
  <div className="flex flex-col gap-6">
    <Typography variant="h6">Sorry to hear that. Please tell us why</Typography>
    {displayPricing !== false && (
      <RadioButton
        name="not-going-ahead-reason"
        label={NotGoingAheadReason.TOO_EXPENSIVE}
        value={NotGoingAheadReason.TOO_EXPENSIVE}
        onChange={onChange}
      />
    )}
    {displayPricing !== false && (
      <RadioButton
        name="not-going-ahead-reason"
        label={NotGoingAheadReason.CANT_AFFORD}
        value={NotGoingAheadReason.CANT_AFFORD}
        onChange={onChange}
      />
    )}
    {hasPrice && financeEnabled && displayPricing !== false && (
      <RadioButton
        name="not-going-ahead-reason"
        label={NotGoingAheadReason.WASNT_ELIGIBLE}
        value={NotGoingAheadReason.WASNT_ELIGIBLE}
        onChange={onChange}
      />
    )}
    <RadioButton
      name="not-going-ahead-reason"
      label={NotGoingAheadReason.ANXIOUS}
      value={NotGoingAheadReason.ANXIOUS}
      onChange={onChange}
    />
    <RadioButton
      name="not-going-ahead-reason"
      label={NotGoingAheadReason.SOMETHING_ELSE}
      value={NotGoingAheadReason.SOMETHING_ELSE}
      onChange={onChange}
    />
  </div>
);
