import { useRef } from 'react'

import { FinanceModalRefCurrent } from '@patient/components/FinanceModal/FinanceModal'

export const useEligibilityChecker = () => {
  const financeModalRef = useRef<FinanceModalRefCurrent>({})

  const openEligibilityChecker = () => {
    financeModalRef?.current?.setIsOpen && financeModalRef.current.setIsOpen(true)
  }
  return {
    financeModalRef,
    openEligibilityChecker,
  }
}
