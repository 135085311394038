import { useMemo } from 'react';

const useShowFinanceOptions = (
  hasInstalments: boolean,
  finalBalance: number,
  maxLoanAmount?: string,
  minLoanAmount?: string,
) => {
  return useMemo(() => {
    if (!maxLoanAmount || !minLoanAmount) return false;

    const maxAmount = parseFloat(maxLoanAmount) * 100;
    const minAmount = parseFloat(minLoanAmount) * 100;

    return hasInstalments && finalBalance <= maxAmount && finalBalance >= minAmount;
  }, [hasInstalments, finalBalance, maxLoanAmount, minLoanAmount]);
};

export default useShowFinanceOptions;
