import { ClipboardEvent } from 'react';
import { Size } from '@library/types';
export const invalidChars = ['-', '+', 'e'];

export const preventInvalidChars = (event: React.KeyboardEvent<HTMLInputElement>) => {
  if (invalidChars.includes(event.key)) {
    event.preventDefault();
  }
};

export const numbersOnly = (event: React.KeyboardEvent<HTMLInputElement>) => {
  const target = event.target as HTMLInputElement;
  const re = /^[0-9\b]+$/;
  if (target.value === '' || !re.test(target.value)) {
    event.preventDefault();
  }
};

export const preventPasteNegative = (event: ClipboardEvent<HTMLInputElement>) => {
  const clipboardData = event.clipboardData;
  if (clipboardData) {
    const pastedData = parseFloat(clipboardData.getData('text'));

    if (pastedData < 0) {
      event.preventDefault();
    }
  }
};

export const getInputSizeClasses = (size: Size) => {
  switch (size) {
    case 'small':
      return 'text-[0.875rem] h-10 px-2.5 rounded-md';
    case 'medium':
      return 'text-[1rem] h-12';
    case 'large':
      return 'text-[1rem] h-14 rounded-xl px-4';
  }
};
