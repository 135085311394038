import Add from './icons/add.svg?react';
import Archive from './icons/archive.svg?react';
import ArrowDown from './icons/arrow-down.svg?react';
import ArrowRight from './icons/arrow-right.svg?react';
import Cat from './icons/cat.svg?react';
import Check from './icons/check.svg?react';
import ChevronDown from './icons/chevron-down.svg?react';
import ChevronLeft from './icons/chevron-left.svg?react';
import ChevronRight from './icons/chevron-right.svg?react';
import ChevronUp from './icons/chevron-up.svg?react';
import Close from './icons/close.svg?react';
import CloseBig from './icons/close-big.svg?react';
import CloseCircle from './icons/close-circle.svg?react';
import Confused1 from './icons/confused1.svg?react';
import Confused2 from './icons/confused2.svg?react';
import Copy from './icons/copy.svg?react';
import Delete from './icons/delete.svg?react';
import Download from './icons/download.svg?react';
import Edit from './icons/edit.svg?react';
import Eye from './icons/eye.svg?react';
import EyeClosed from './icons/eye-closed.svg?react';
import File from './icons/file.svg?react';
import Help from './icons/help.svg?react';
import Info from './icons/info.svg?react';
import Link from './icons/link.svg?react';
import Loader from './icons/loader.svg?react';
import Logout from './icons/log-out.svg?react';
import Logo from './icons/logo.svg?react';
import Mail from './icons/mail.svg?react';
import Moon from './icons/moon.svg?react';
import MoreDots from './icons/more-dots.svg?react';
import Note from './icons/note.svg?react';
import Phone from './icons/phone.svg?react';
import Play from './icons/play.svg?react';
import Plus from './icons/plus.svg?react';
import Pound from './icons/pound.svg?react';
import PracticeSettings from './icons/practice-settings.svg?react';
import Resend from './icons/resend.svg?react';
import Search from './icons/search.svg?react';
import Send from './icons/send.svg?react';
import Settings from './icons/settings.svg?react';
import Sort from './icons/sort.svg?react';
import SortAsc from './icons/sort-asc.svg?react';
import SortDsc from './icons/sort-dsc.svg?react';
import Sun from './icons/sun.svg?react';
import Upload from './icons/upload.svg?react';
import User from './icons/user.svg?react';
import View from './icons/view.svg?react';

export const icons = {
  'add': Add,
  'archive': Archive,
  'arrow-down': ArrowDown,
  'arrow-right': ArrowRight,
  'check': Check,
  'chevron-down': ChevronDown,
  'chevron-right': ChevronRight,
  'chevron-left': ChevronLeft,
  'chevron-up': ChevronUp,
  'close-big': CloseBig,
  'close-circle': CloseCircle,
  'close': Close,
  'copy': Copy,
  'delete': Delete,
  'download': Download,
  'edit': Edit,
  'eye': Eye,
  'eye-closed': EyeClosed,
  'file': File,
  'help': Help,
  'info': Info,
  'link': Link,
  'loader': Loader,
  'logout': Logout,
  'mail': Mail,
  'moon': Moon,
  'more-dots': MoreDots,
  'note': Note,
  'phone': Phone,
  'play': Play,
  'plus': Plus,
  'practice-settings': PracticeSettings,
  'pound': Pound,
  'resend': Resend,
  'search': Search,
  'send': Send,
  'settings': Settings,
  'sort': Sort,
  'sort-asc': SortAsc,
  'sort-dsc': SortDsc,
  'sun': Sun,
  'upload': Upload,
  'user': User,
  'cat': Cat,
  'confused1': Confused1,
  'confused2': Confused2,
  'logo': Logo,
  'view': View,
};

export type IconName = keyof typeof icons;
