import { ReactNode } from 'react';
import { Disclosure } from '@headlessui/react';
import { clsx } from 'clsx';

import { Icon, IconNameType } from '@shared/components/Icon';
import { Typography } from '@shared/components/Typography';

type AccordianIcon = {
  open: IconNameType;
  close: IconNameType;
};

export interface AccordianItem {
  title: string;
  description: ReactNode;
  content?: string;
  titleInfo?: string;
  defaultOpen?: boolean;
}

type Props = {
  showImages?: boolean;
  header?: string;
  icons: AccordianIcon;
  items: AccordianItem[];
  subHeading?: string;
};

export const CheckoutAccordian = ({ icons, header, items, subHeading }: Props) => {
  return (
    <div className="w-full">
      {header && (
        <Typography variant="h3" className={clsx(subHeading ? 'mb-4' : 'mb-12')}>
          {header}
        </Typography>
      )}
      {subHeading && (
        <Typography variant="b20" className="mb-12 text-grey-600">
          {subHeading}
        </Typography>
      )}
      {items.map(({ title, description, titleInfo, defaultOpen }, index) => (
        <Disclosure
          key={title}
          as="div"
          defaultOpen={defaultOpen}
          className={clsx('border-grey-200 py-4', index < items.length - 1 ? 'border-b' : '')}
        >
          {({ open }) => (
            <>
              <Disclosure.Button as="div" className={open ? 'mb-4' : ''}>
                <button className="item-start flex w-full justify-between">
                  <div className="items-start flex-col md:flex-row flex gap-y-4 md:gap-y-0 gap-x-6 justify-start w-full">
                    <div className="flex gap-4 w-full justify-between items-center">
                      <Typography variant="h6" className="text-left">
                        {title}
                      </Typography>
                      {titleInfo && (
                        <Typography
                          variant="l12"
                          className="bg-grey-100 text-accent-accent1-900 px-3 py-[10px] rounded mr-2"
                        >
                          {titleInfo}
                        </Typography>
                      )}
                    </div>
                  </div>
                  <Icon size={30} name={open ? icons.open : icons.close} className="stroke-primary-dark" />
                </button>
              </Disclosure.Button>
              <Disclosure.Panel className="text-left">
                <Typography
                  element={typeof description === 'object' ? 'div' : 'p'}
                  variant="b16"
                  className="text-grey-600"
                >
                  {description}
                </Typography>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      ))}
    </div>
  );
};
