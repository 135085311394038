import { lazy } from 'react';
import { Route } from 'react-router-dom';

const LoginPage = lazy(() => import('@library/pages/practice/LoginPage'));
const ErrorPage = lazy(() => import('@shared/pages/ErrorPage'));
const PatientDashboard = lazy(() => import('@library/pages/practice/PatientDashboard'));
const ResetPasswordPage = lazy(() => import('@library/pages/practice/ResetPasswordPage'));

const PracticeAccountSuccess = lazy(() => import('@practice/pages/AccountSuccess'));
const PracticeSupport = lazy(() => import('@practice/pages/Support'));
const ProtectedDashboardLayout = lazy(() => import('@practice/templates/ProtectedDashboardLayout'));
const PracticeTreatmentPreviewLayout = lazy(() => import('@practice/templates/PreviewLayout'));
const AuthLayout = lazy(() => import('@practice/templates/AuthLayoutNewDS/'));
const ForgotPasswordConfirm = lazy(() => import('@library/pages/practice/ForgotPasswordPage/Confirm'));
const ForgotPasswordSendCode = lazy(() => import('@library/pages/practice/ForgotPasswordPage/SendCode'));
const CookieDeclarationPage = lazy(() => import('@practice/pages/CookieDeclarationPage'));
const AccountPage = lazy(() => import('@practice/pages/AccountPage'));
const TreatmentPreview = lazy(() => import('@practice/pages/TreatmentPreview'));
const ViewTreatmentGuidePage = lazy(() => import('@practice/pages/ViewTreatmentGuidePage'));

import { routePaths } from '@shared/utils/urls';

import { RoutesCookieWrapper } from './components/RoutesCookieWrapper';

const DashboardRoutes = (
  <Route path={routePaths.dashboard} element={<ProtectedDashboardLayout />}>
    <Route index element={<PatientDashboard />} />
    <Route path={routePaths.account} element={<AccountPage />} />
    <Route path={routePaths.support} element={<PracticeSupport />} />
  </Route>
);

const PreviewRoutes = (
  <Route path={routePaths.treatmentPreview} element={<PracticeTreatmentPreviewLayout />}>
    <Route index element={<TreatmentPreview />} />
  </Route>
);

const PracticeRoutes = (
  <Route path={routePaths.practice} element={<RoutesCookieWrapper />}>
    <Route path={routePaths.error} element={<ErrorPage />} />
    <Route path={routePaths.cookieDeclaration} element={<CookieDeclarationPage />} />
    <Route path={routePaths.accountSuccess} element={<PracticeAccountSuccess />} />
    <Route path={routePaths.login} element={<AuthLayout />}>
      <Route index element={<LoginPage />} />
      <Route path={routePaths.resetPassword} element={<ResetPasswordPage />} />
      <Route path={routePaths.forgotPassword} element={<ForgotPasswordSendCode />} />
      <Route path={routePaths.forgotPasswordConfirm} element={<ForgotPasswordConfirm />} />
    </Route>
    {DashboardRoutes}
    {PreviewRoutes}
    <Route path={routePaths.practiceViewTreatmentGuide} element={<PracticeTreatmentPreviewLayout />}>
      <Route index element={<ViewTreatmentGuidePage />} />
    </Route>
  </Route>
);

export default PracticeRoutes;
