import { Fragment, useEffect, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import { Heading, Paragraph } from '@library/components/atoms';
import { Button, Checkbox } from '@library/components/molecules';
import useShowFinanceOptions from '@library/hooks/useShowFinanceOptions/useShowFinanceOptions';
import { t } from 'i18next';

import { Accordian } from '@shared/components/Accordian';
import { OffCanvas } from '@shared/components/OffCanvas/OffCanvas';
import { Spinner } from '@shared/components/Spinner';
import { Typography } from '@shared/components/Typography';
import { MaxAvailableInstalment, MinAvailableInstalment } from '@shared/content-blocks/FinanceOptionsModule';
import { Disclaimer } from '@shared/content-blocks/FinanceOptionsModule/Disclaimer';
import { useGetPracticeInstalments } from '@shared/data/practice/hooks';
import { DivideBuyInstalment, PricingType } from '@shared/data/types';
import { divideBuyCurrencyFormatter } from '@shared/utils/numberFormatter';

export interface CheckoutFinanceOptionsProps {
  price: number;
  deposit?: number;
  displayPricing?: boolean;
  treatments: string[];
  practiceName?: string;
  pricingType?: PricingType;
  selectedTerm: string;
  setSelectedTerm: (term: string) => void;
}

export const CheckoutFinanceOptions = ({
  price,
  deposit,
  displayPricing,
  selectedTerm,
  setSelectedTerm,
}: CheckoutFinanceOptionsProps) => {
  const [offCanvasMenuOpen, setOffCanvasMenuOpen] = useState<boolean>(false);
  const treatmentGuideId = localStorage.getItem('treatmentGuideId') || '';
  const practiceId = treatmentGuideId.split(':')[0];
  const finalBalance = price - (deposit || 0);

  const {
    data: instalments,
    isLoading,
    refetch: getInstalments,
  } = useGetPracticeInstalments({
    amount: String(finalBalance / 100),
    practiceId,
  });

  useEffect(() => {
    if (finalBalance && practiceId) getInstalments();
  }, [finalBalance, practiceId, getInstalments]);

  const instalmentsData = useMemo(() => instalments?.data.terms || [], [instalments?.data]);

  const maxLoanAmount = useMemo(
    () =>
      Math.max(
        ...(
          instalmentsData.filter(
            ({ max_price, type }) => max_price !== null && type !== 'representative_example',
          ) as MaxAvailableInstalment[]
        ).map(({ max_price }) => max_price),
      ).toString(),
    [instalmentsData],
  );

  const minLoanAmount = useMemo(
    () =>
      Math.max(
        ...(
          instalmentsData.filter(
            ({ min_price, type }) => min_price !== null && type !== 'representative_example',
          ) as MinAvailableInstalment[]
        ).map(({ min_price }) => min_price),
      ).toString(),
    [instalmentsData],
  );

  const availableInstalments = useMemo(
    () => instalmentsData.filter(({ available, type }) => type !== 'representative_example' && available != false),
    [instalmentsData],
  );

  const representativeExample = instalmentsData.find(
    (item: DivideBuyInstalment) => item.type === 'representative_example',
  );

  const showOptions = useShowFinanceOptions(
    Boolean(availableInstalments.length),
    finalBalance,
    maxLoanAmount,
    minLoanAmount,
  );

  const isAboveMax = finalBalance >= parseFloat(maxLoanAmount!);

  return (
    <div className="flex flex-col items-start w-full">
      <Heading variant="h6" className="mb-4">
        Finance options
      </Heading>
      <Paragraph size="medium" className="mb-4">
        Here are the available finance options and estimated monthly payments. You can check your eligibility with no
        impact on your credit score.
      </Paragraph>
      <div className="flex gap-2 items-center">
        <Paragraph size={'caption'} className="!text-foregroundNeutralSecondary">
          {t('plans.planSelection.spreadCost.poweredBy')}
        </Paragraph>
        <img src="/images/patient/dividebuy-logo-small.svg" alt="logo" className="flex-grow:1 w-24" />
      </div>
      {displayPricing !== false && (
        <>
          {availableInstalments && maxLoanAmount && !isLoading ? (
            <>
              {showOptions ? (
                <div>
                  <div className="flex flex-col gap-y-4 w-full mt-6">
                    {availableInstalments.map((instalment, index) => {
                      const selectedTermValue = `${instalment.type}-${instalment.term_length}`;
                      return (
                        <Checkbox
                          key={selectedTermValue}
                          onChange={() => setSelectedTerm(selectedTerm !== selectedTermValue ? selectedTermValue : '')}
                          checked={selectedTerm === selectedTermValue}
                        >
                          <Fragment key={instalment.monthly_instalment_cost}>
                            <div data-testid={`instalment-option-${index}`} className="w-full gap-3">
                              <div>
                                <Trans
                                  i18nKey="patient.myGuidePage.loanCalculatorBlock.financeOptions.instalment.heading"
                                  components={{
                                    typography: (
                                      <Typography variant="l16" element="span" className="text-primary-dark" />
                                    ),
                                    monthlyCost: (
                                      <Typography variant="l16" element="span" className="text-primary-dark" />
                                    ),
                                  }}
                                  values={{
                                    monthly_instalment_cost: divideBuyCurrencyFormatter(
                                      instalment.monthly_instalment_cost,
                                      2,
                                    ),
                                    term_length: instalment.term_length,
                                  }}
                                />
                              </div>
                              <div>
                                <Trans
                                  i18nKey="patient.myGuidePage.loanCalculatorBlock.financeOptions.instalment.subHeading"
                                  components={{
                                    typography: (
                                      <Typography variant="b14" element="span" className="text-primary-dark" />
                                    ),
                                  }}
                                  values={{
                                    interest_rate: instalment.interest_rate,
                                    total_to_pay: divideBuyCurrencyFormatter(instalment.total_to_pay),
                                  }}
                                />
                              </div>
                            </div>
                          </Fragment>
                        </Checkbox>
                      );
                    })}
                    <div className="mt-6">
                      <Disclaimer repExample={representativeExample} instalments={availableInstalments} />
                    </div>
                    <div className="bg-primary-light p-6 flex md:flex-row gap-4 md:gap-6 items-center rounded-lg w-full">
                      <Typography variant="b16" element="span" className="text-primary-dark">
                        {t('patient.patientCheckoutPage.checkoutFinanceOptions.borrowingDisclaimer')}
                      </Typography>
                    </div>
                    <div className="flex flex-col items-start">
                      <Button
                        mode="accent"
                        appearance="subtle"
                        leftIcon="help"
                        size="medium"
                        onClick={() => setOffCanvasMenuOpen(true)}
                        testId="view-finance-faqs"
                        text={t('patient.myGuidePage.loanCalculatorBlock.links.viewFaqs')}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <Trans
                  i18nKey={
                    isAboveMax
                      ? 'patient.myGuidePage.loanCalculatorBlock.financeOptions.aboveMaximum'
                      : 'patient.myGuidePage.loanCalculatorBlock.financeOptions.belowMinimum'
                  }
                  components={{
                    typography: <Typography variant="b16" element="span" className="text-primary-dark" />,
                  }}
                  values={{
                    max_loan_amount: divideBuyCurrencyFormatter(parseFloat(maxLoanAmount!)),
                    min_loan_amount: divideBuyCurrencyFormatter(parseFloat(minLoanAmount!)),
                  }}
                />
              )}
            </>
          ) : (
            <div className="relative w-full flex items-center py-4">
              <Spinner />
            </div>
          )}
        </>
      )}
      <OffCanvas setIsOpen={setOffCanvasMenuOpen} isOpen={offCanvasMenuOpen}>
        <Accordian
          icons={{ open: 'minus', close: 'plus' }}
          className="text-primary-dark"
          header={t('patient.treatmentsPage.faq.title')}
          items={Array.from({ length: 14 }, (_, index) => {
            const itemIndex = (index + 1) as unknown as 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 14;
            if (itemIndex === 3) {
              return {
                title: t(`patient.financePage.faqBlock.question${itemIndex}.text`),
                description: (
                  <>
                    {t('patient.financePage.faqBlock.question3.answer')}
                    <ul role="list" className="list-disc ml-6 my-2">
                      <li>{t('patient.financePage.faqBlock.question3.limitMonth12')}</li>
                      <li>{t('patient.financePage.faqBlock.question3.limitMonth24')}</li>
                      <li>{t('patient.financePage.faqBlock.question3.limitMonth36')}</li>
                    </ul>
                    {t('patient.financePage.faqBlock.question3.info')}
                  </>
                ),
              };
            } else if (itemIndex === 10) {
              return {
                title: t(`patient.financePage.faqBlock.question${itemIndex}.text`),
                description: (
                  <p>
                    {t('patient.financePage.faqBlock.question10.answer')}
                    <a
                      className="text-primary-main underline decoration-2 underline-offset-4"
                      target="_blank"
                      href={t('patient.financePage.faqBlock.question10.link.href')}
                      rel="noreferrer"
                    >
                      {t('patient.financePage.faqBlock.question10.link.text')}
                    </a>
                  </p>
                ),
              };
            } else {
              return {
                title: t(`patient.financePage.faqBlock.question${itemIndex}.text`),
                description: t(`patient.financePage.faqBlock.question${itemIndex}.answer`),
              };
            }
          })}
        />
      </OffCanvas>
    </div>
  );
};
