import { memo } from 'react';

import { icons } from '../Icon/utils';

export interface IllustrationProps {
  type: 'cat' | 'confused1' | 'confused2' | 'logo';
  size: 'small' | 'medium' | 'large';
}

export const Illustration = memo(({ type, size }: IllustrationProps) => {
  let sizeClasses;
  switch (size) {
    case 'small':
      sizeClasses = 'w-[5.5rem] h-[5.5rem]';
      break;
    case 'medium':
      sizeClasses = 'w-[7.5rem] h-[7.5rem]';
      break;
    case 'large':
      sizeClasses = 'w-[9rem] h-[9rem]';
  }

  let Image;
  switch (type) {
    case 'cat':
      Image = icons.cat;
      break;
    case 'confused1':
      Image = icons.confused1;
      break;
    case 'confused2':
      Image = icons.confused2;
      break;
    case 'logo':
      Image = icons.logo;
  }
  return <Image className={sizeClasses} />;
});

Illustration.displayName = 'Illustration';
