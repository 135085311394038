import { useEffect, useMemo } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';

import LoggedInNav from '@patient/components/LoggedInNav';
import { Footer } from '@patient/templates/Footer/Footer';
import { Spinner } from '@shared/components/Spinner';
import { useGetPractice, useGetTreatmentGuide } from '@shared/data/practice/hooks';
import { Accessor } from '@shared/data/types';
import { IntercomService } from '@shared/services/Intercom';

export const AuthWrapper = () => {
  const treatmentGuideId = localStorage.getItem('treatmentGuideId') || '';
  const practiceId = treatmentGuideId.split(':')[0];
  const intercomService = useMemo(() => new IntercomService(), []);

  const {
    data: treatmentGuide,
    isLoading: treatmentGuideIsLoading,
    error: treatmentGuideError,
  } = useGetTreatmentGuide(treatmentGuideId, Accessor.PATIENT);

  const {
    data: practice,
    isLoading: practiceIsLoading,
    error: practiceError,
  } = useGetPractice(practiceId, Accessor.PATIENT);

  const navigate = useNavigate();

  const treatmentGuideData = treatmentGuide?.data;

  useEffect(() => {
    if (treatmentGuideData) {
      intercomService.bootIntercom(
        treatmentGuideData.firstName!,
        treatmentGuideData.lastName!,
        treatmentGuideData.email!,
        'Patient',
        practice?.data.name,
      );
    }
  }, [treatmentGuideData, practice, intercomService]);

  if (!treatmentGuideId) return <Navigate to="/patient/login" />;

  if (practiceIsLoading || treatmentGuideIsLoading) return <Spinner centered />;

  if (practiceError || treatmentGuideError) {
    navigate('/patient/error', { replace: true });
    return null;
  }

  return (
    <div>
      {treatmentGuide && (
        <LoggedInNav
          patientName={`${treatmentGuide.data.firstName} ${treatmentGuide.data.lastName}`}
          logoUrl={practice?.data?.logoUrl || ''}
        />
      )}
      <main className="xl:pt-0">{<Outlet />}</main>
      {practice && <Footer practiceName={practice?.data.name} />}
    </div>
  );
};
