import { Icon } from '@shared/components/Icon';
import { Typography } from '@shared/components/Typography';
import { copyInfo } from '@shared/utils/copyInfo';

interface CheckoutInterestedModuleProps {
  practiceNumber: string;
  email: string;
}

export const CheckoutInterestedModule = ({ practiceNumber, email }: CheckoutInterestedModuleProps) => (
  <div className="flex flex-col gap-6" data-testid="interested-module">
    <Typography variant="h6">Thanks for letting us know you’re interested.</Typography>
    <div>
      <Typography variant="b14">Next step</Typography>
      <Typography variant="h6">Book your appointment</Typography>
    </div>
    <div className="flex flex-col gap-4">
      <div className="flex gap-6 bg-white rounded-md p-4">
        <Icon className="stroke-accent1-900 stroke-2" size={24} name="phone" />
        {practiceNumber}
      </div>
      <div className="flex gap-6 bg-white rounded-md p-4 items-center">
        <span className="w-6">
          <Icon className="stroke-accent1-900 stroke-2" size={24} name="mail" />
        </span>
        <div className="w-[100% - 10rem] break-all">{email}</div>
        <button className="w-6 inline" onClick={() => copyInfo(email)}>
          <Icon name="copy" className="stroke-primary-main active:bg-gray-400 rounded stroke-2" />
        </button>
      </div>
    </div>
  </div>
);
