import { PatientAction } from '@shared/data/types';

import { TagProps } from './Tag';

export const baseClasses = `
  rounded inline-flex items-center justify-center relative
`;

export const disabledClasses = `
  !bg-backgroundNeutralSubtle !text-foregroundNeutralDisabled !cursor-not-allowed
`;

export const errorClasses = `
  !bg-backgroundErrorSoft
`;

export const getAppearanceClasses = (appearance?: TagProps['appearance']) => {
  switch (appearance) {
    case 'outline':
      return 'bg-white border border-borderNeutralDefault !text-foregroundNeutralSecondary';
    case 'subtle':
      return 'bg-backgroundNeutralSubtle text-foregroundNeutralPrimary';
    case 'oat':
      return 'bg-backgroundOatSubtle text-foregroundNeutralPrimary';
    case 'sage':
      return 'bg-backgroundSageSubtle text-foregroundNeutralPrimary';
    case 'duckEgg':
      return 'bg-backgroundDuckeggSubtle text-foregroundNeutralPrimary';
    default:
      return 'bg-backgroundNeutralSubtle text-foregroundNeutralPrimary';
  }
};

export const getSizeClasses = (size?: TagProps['size']) => {
  switch (size) {
    case 'small':
      return `h-6 px-1.5 gap-x-1 rounded`;
    case 'medium':
      return `h-8 px-2 gap-x-1.5 rounded-md`;
    case 'large':
      return `h-10 px-2.5 gap-x-2 rounded-lg`;
    default:
      return `h-8 px-2 gap-x-1.5 rounded-md`;
  }
};

export const getTextSize = (size?: TagProps['size']) => {
  switch (size) {
    case 'small':
      return 'caption';
    case 'medium':
      return 'small';
    case 'large':
      return 'small';
    default:
      return 'small';
  }
};

export const getTemplateData = (template?: TagProps['template']) => {
  switch (template) {
    case PatientAction.GUIDE_SENT:
      return {
        text: 'Guide sent',
        baseClasses: 'bg-white border border-borderNeutralDefault',
        textClasses: '!text-foregroundNeutralSecondary',
        iconColor: '!text-alphaDark20',
      };
    case PatientAction.FOLLOW_UP_SENT:
      return {
        text: 'Follow-up sent',
        baseClasses: 'bg-white border border-borderNeutralDefault',
        textClasses: '!text-foregroundNeutralSecondary',
        iconColor: '!text-alphaDark20',
      };
    case PatientAction.VIEWED:
      return {
        text: 'Viewed',
        baseClasses: '!bg-white border border-borderNeutralDefault',
        textClasses: '!text-foregroundNeutralSecondary',
        iconColor: '!text-alphaDark20',
      };
    case PatientAction.INTERESTED:
      return {
        text: 'Interested',
        baseClasses: '!bg-backgroundWarningSoftHover',
        textClasses: '!text-backgroundWarningDefaultPressed',
        iconColor: '!text-backgroundWarningDefaultPressed',
      };
    case PatientAction.NOT_SURE:
      return {
        text: 'Not sure',
        baseClasses: '!bg-backgroundWarningSoftHover',
        textClasses: '!text-backgroundWarningDefaultPressed',
        iconColor: '!text-backgroundWarningDefaultPressed',
      };
    case PatientAction.FINANCE_REFUNDED:
      return {
        text: 'Finance refunded',
        baseClasses: '!bg-backgroundSageSubtle',
        textClasses: '!text-backgroundSuccessDefaultPressed',
        iconColor: '!text-backgroundSuccessDefaultPressed',
      };
    case PatientAction.FINANCE_PROCESSED:
      return {
        text: 'Finance processed',
        baseClasses: '!bg-backgroundSageSubtle',
        textClasses: '!text-backgroundSuccessDefaultPressed',
        iconColor: '!text-backgroundSuccessDefaultPressed',
      };
    case PatientAction.FINANCE_DECLINED:
      return {
        text: 'Finance declined',
        baseClasses: '!bg-backgroundErrorSoftHover',
        textClasses: '!text-backgroundErrorDefaultPressed',
        iconColor: 'text-backgroundErrorDefaultPressed',
      };
    case PatientAction.PAID_WITH_FINANCE:
      return {
        text: 'Finance accepted',
        baseClasses: '!bg-backgroundWarningSoftHover',
        textClasses: '!text-backgroundWarningDefaultPressed',
        iconColor: '!text-backgroundWarningDefaultPressed',
      };
    case PatientAction.PAY_IN_PRACTICE:
      return {
        text: 'Pay in practice',
        baseClasses: '!bg-backgroundSageSubtle',
        textClasses: '!text-backgroundSuccessDefaultPressed',
        iconColor: '!text-backgroundSuccessDefaultPressed',
      };
    case PatientAction.DECLINED:
      return {
        text: 'Declined',
        baseClasses: '!bg-backgroundErrorSoftHover',
        textClasses: '!text-backgroundErrorDefaultPressed',
        iconColor: 'text-backgroundErrorDefaultPressed',
      };
    case PatientAction.GUIDE_BOUNCED:
      return {
        text: 'Not delivered',
        baseClasses: '!bg-backgroundErrorSoftHover',
        textClasses: '!text-backgroundErrorDefaultPressed',
        iconColor: '!text-backgroundErrorDefaultPressed',
      };
    case 'OUTLINE':
      return {
        text: undefined,
        baseClasses: 'bg-white border border-borderNeutralDefault',
        textClasses: '!text-foregroundNeutralPrimary',
        iconColor: '!text-foregroundNeutralSecondary',
      };
  }
};

export const treatmentTemplateMap: { [key: string]: string } = {
  'Adhesive bridge': 'bg-avatars03',
  'Clear aligners': 'bg-avatars14',
  'Composite bonding': 'bg-avatars18',
  'Crown supported bridge': 'bg-avatars05',
  'Crown': 'bg-avatars02',
  'Dental implant': 'bg-avatars08',
  'Dentures': 'bg-avatars06',
  'Filling': 'bg-avatars12',
  'Fixed braces': 'bg-avatars12',
  'Implant-retained bridge': 'bg-avatars03',
  'Implant-retained dentures': 'bg-avatars09',
  'In-practice crown': 'bg-avatars08',
  'Inlays / onlays': 'bg-avatars03',
  'Periodontal treatment': 'bg-avatars16',
  'Root canal': 'bg-avatars07',
  'Routine hygienist': 'bg-avatars18',
  'At-home teeth whitening': 'bg-avatars04',
  'In-practice teeth whitening': 'bg-avatars06',
  'Tooth extraction': 'bg-avatars15',
  'Veneers': 'bg-avatars04',
};
export const getTreatmentAppearance = (text: TagProps['text']) => {
  return typeof text === 'string' ? treatmentTemplateMap[text] : undefined;
};
