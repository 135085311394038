export const baseClasses = 'flex items-center rounded-full duration-200';
export const baseSliderClasses = 'rounded-full flex items-center cursor-pointer border-2';
export const baseDotClasses = 'bg-white items-center flex justify-center rounded-full duration-200';
export const disabledClasses =
  '!bg-foregroundNeutralDisabled !border-foregroundNeutralDisabled !hover:bg-foregroundNeutralDisabled !cursor-not-allowed';

export const getDotSizeClasses = (size: 'small' | 'medium' | 'large') => {
  switch (size) {
    case 'small':
      return 'h-[0.75rem] w-[0.75rem]';
    case 'medium':
      return 'h-[1rem] w-[1rem]';
    case 'large':
      return 'h-[1.25rem] w-[1.25rem]';
  }
};

export const getSliderSizeClasses = (size: 'small' | 'medium' | 'large') => {
  switch (size) {
    case 'small':
      return 'h-[1rem] w-[1.75rem]';
    case 'medium':
      return 'h-[1.25rem] w-[2rem]';
    case 'large':
      return 'h-[1.5rem] w-[2.5rem]';
  }
};

export const getSliderStateClasses = (checked?: boolean) => {
  switch (checked) {
    case true:
      return 'bg-backgroundBrandDefault border-backgroundBrandDefault';
    case false:
      return 'bg-foregroundNeutralTertiary border-foregroundNeutralTertiary';
    default:
      return 'bg-foregroundNeutralTertiary border-foregroundNeutralTertiary';
  }
};

export const getSliderHoverClasses = (checked?: boolean) => {
  switch (checked) {
    case true:
      return 'hover:bg-backgroundBrandDefaultHover hover:border-backgroundBrandDefaultHover';
    case false:
      return 'hover:bg-foregroundNeutralTertiaryHover hover:border-foregroundNeutralTertiaryHover';
    default:
      return 'hover:bg-foregroundNeutralTertiaryHover hover:border-foregroundNeutralTertiaryHover';
  }
};

export const getDotSlideClass = (size: 'small' | 'medium' | 'large') => {
  switch (size) {
    case 'small':
      return 'translate-x-[0.75rem]';
    case 'medium':
      return 'translate-x-[0.75rem]';
    case 'large':
      return 'translate-x-[1rem]';
  }
};
