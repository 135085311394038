import { memo } from 'react';
import clsx from 'clsx';

export interface DividerProps extends React.HTMLAttributes<HTMLElement> {
  margin: 'small' | 'medium' | 'large';
  color?: string;
  className?: string;
}

export const Divider = memo(({ margin, color = 'bg-borderNeutralDefault', className }: DividerProps) => {
  let heightClass;
  switch (margin) {
    case 'medium':
      heightClass = 'h-2';
      break;
    case 'large':
      heightClass = 'h-4';
      break;
  }
  return (
    <div className={clsx('flex items-center w-full', heightClass, className)}>
      <hr className={clsx(color, 'block h-[1px] border-none w-full')} />
    </div>
  );
});

Divider.displayName = 'Divider';
