import { NavLink as NavigationLink, To } from 'react-router-dom';
import clsx from 'clsx';

import { Button, ButtonProps } from '@shared/components/Button';
import { IconNameType } from '@shared/components/Icon';

interface Props extends ButtonProps {
  /** The icon to appear on the button */
  icon?: IconNameType;
  path: To;
  end?: boolean;
  text: string;
  onClick?: () => void;
  iconSize?: number;
  target?: string;
  fullWidth?: boolean;
}

/**
 * This component wraps the existing shared Button component in react router's NavLink component
 * this is for the purpose having buttons that redirect the page to be rendered semantically as anchors
 * whilst allowing MixPanel track events to occur when this happens
 */
export const NavButton = ({ path, fullWidth, className, end, target, onClick, ...props }: Props) => {
  const handleClick = () => {
    onClick && onClick();
  };

  return (
    <NavigationLink onClick={handleClick} to={path} end={end} target={target} className={fullWidth ? 'w-full' : ''}>
      <Button {...props} className={clsx(className, fullWidth ? 'w-full' : '')} />
    </NavigationLink>
  );
};
