import { HeadingVariant } from './Heading';

const h1Styles = `text-[2.5rem] leading-[3rem] tracking-[-0.01em]`;
const h2Styles = `text-[2.25rem] leading-[2.75rem] tracking-normal`;
const h3Styles = `text-[2rem] leading-[2.5rem] tracking-normal`;
const h4Styles = `text-[1.75rem] leading-[2.25rem] tracking-normal`;
const h5Styles = `text-[1.5rem] leading-[2rem] tracking-normal`;
const h6Styles = `text-[1.25rem] leading-[1.75rem] tracking-normal`;

export const getHeadingClasses = (variant: HeadingVariant) => {
  switch (variant) {
    case 'h1':
      return h1Styles; //large
    case 'h2':
      return h2Styles; //medium
    case 'h3':
      return h3Styles; //small
    case 'h4':
      return h4Styles; //xSmall
    case 'h5':
      return h5Styles; //xxSmall
    case 'h6':
      return h6Styles; //xxxSmall
  }
};
