import { memo } from 'react';
import { Icon } from '@library/components/atoms/Icon';
import { IconName } from '@library/components/atoms/Icon/utils';
import { getTextSize } from '@library/utils';
import clsx from 'clsx';

export type BadgeAppearance =
  | 'none'
  | 'default'
  | 'outlined'
  | 'sage'
  | 'blue'
  | 'salmon'
  | 'warning'
  | 'error'
  | 'positive'
  | 'disabled';
export interface BadgeProps extends React.HTMLAttributes<HTMLElement> {
  leftIcon?: IconName;
  rightIcon?: IconName;
  appearance?: BadgeAppearance;
  text: string;
  size: 'xSmall' | 'small';
}

export const Badge = memo(
  ({ className, text, size, leftIcon, rightIcon, appearance = 'default', ...props }: BadgeProps) => {
    let classes;
    switch (appearance) {
      case 'none':
        classes = 'text-foregroundNeutralSecondary !font-normal !px-0 !w-full';
        break;
      case 'default':
        classes = 'text-foregroundNeutralPrimary bg-backgroundNeutralSubtle';
        break;
      case 'outlined':
        classes = 'text-foregroundNeutralSecondary outline outline-borderNeutralDefault';
        break;
      case 'sage':
        classes = 'text-foregroundSagePrimary bg-backgroundSageSoft';
        break;
      case 'blue':
        classes = 'text-foregroundBluePrimary bg-backgroundBlueSoft';
        break;
      case 'salmon':
        classes = 'text-foregroundSalmonPrimary bg-backgroundSalmonSoft';
        break;
      case 'warning':
        classes = 'text-foregroundWarningPrimary bg-backgroundWarningSoft';
        break;
      case 'error':
        classes = 'text-foregroundErrorPrimary bg-backgroundErrorSoft';
        break;
      case 'positive':
        classes = 'text-foregroundSuccessPrimary bg-backgroundSuccessSoft';
        break;
      case 'disabled':
        classes = 'text-foregroundNeutralDisabled bg-backgroundNeutralSubtleDisabled';
        break;
    }
    return (
      <span
        {...props}
        data-testid="badge"
        className={clsx(
          'inline-flex text-[0.75rem] rounded-[999px] font-medium',
          classes,
          size === 'xSmall' ? 'px-1.5 gap-1' : 'px-2 py-[2px] gap-2',
          className,
        )}
      >
        {leftIcon && <Icon size="xSmall" color={classes} iconName={leftIcon} />}
        {text && <span className={getTextSize(size)}>{text}</span>}
        {rightIcon && <Icon size="xSmall" color={classes} iconName={rightIcon} />}
      </span>
    );
  },
);

Badge.displayName = 'Badge';
