import { HTMLAttributes } from 'react';
import clsx from 'clsx';

const smallStyles = `font-normal text-[0.875rem] leading-[1rem] tracking-[0.0175rem] text-neutral-primary`;

type LabelSize = 'small' | 'medium' | 'large';

interface ParagraphProps extends HTMLAttributes<HTMLLabelElement> {
  size: LabelSize;
}

export const Label = ({ className, children, size, ...props }: ParagraphProps) => (
  <label className={clsx(className, smallStyles)} {...props}>
    {children}
  </label>
);
