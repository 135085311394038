import { useCallback, useMemo } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { RawDraftContentState } from 'draft-js';

import { formatExistingNotes } from '@practice/components/NotesEditor/utils';

type NotesProps = {
  notes: string;
};

export const DentistNotes = ({ notes }: NotesProps) => {
  const formatNotes = useCallback(() => {
    if (notes.includes('blocks') && notes.includes('entityMap') && typeof JSON.parse(notes) === 'object') {
      return JSON.parse(notes) as RawDraftContentState;
    } else if (notes) {
      // This block is to format existing notes that were not created with the rich text editor
      return formatExistingNotes(notes);
    }
    return { blocks: [], entityMap: {} } as RawDraftContentState;
  }, [notes]);

  const formattedNotes = useMemo(() => formatNotes(), [formatNotes]);

  if (formattedNotes.blocks.length === 1 && !formattedNotes.blocks?.[0].text) {
    return null;
  }

  return (
    <div data-testid="notes" id="module-guide">
      <Editor
        contentState={formattedNotes}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName={
          'editorClassName [&_a]:text-primary-main p-0! text-gray-600 rounded-lg [&_div_>_div_>_div_>_div_>_div_>_div]:pb-4'
        }
        toolbarHidden
        readOnly
      />
    </div>
  );
};
