import { MutableRefObject, useEffect, useState } from 'react';

import { Modal } from '@shared/components/Modal';
import { Spinner } from '@shared/components/Spinner';
import { usePostDividebuySoftSearch } from '@shared/data/practice/hooks';

export type FinanceModalRefCurrent = { setIsOpen?: (v: boolean) => void };
export type FinanceModalRef = MutableRefObject<FinanceModalRefCurrent | undefined>;

type Props = {
  financeModalRef?: FinanceModalRef;
  price: number;
};

export const FinanceModal = ({ financeModalRef, price }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { data: softSearch, mutate } = usePostDividebuySoftSearch();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (isOpen) {
      mutate(
        {
          amount: price,
        },
        {
          onError: () => setIsLoading(false),
          onSettled: () => setIsLoading(false),
          onSuccess: () => setIsLoading(false),
        },
      );
    }
    /* This line has been disabled because adding mutate to the dependecny array will cause infinite loop of fetching and a memory leak */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  if (financeModalRef?.current) {
    financeModalRef.current.setIsOpen = setIsOpen;
  }

  return (
    <Modal className="md:max-w-[450px]" testId="finance-block-modal" isOpen={isOpen} setIsOpen={setIsOpen}>
      {isLoading && <Spinner />}

      {softSearch?.data.url && (
        <iframe
          data-testid="test frame"
          className="h-[780px] w-full mx-auto"
          src={softSearch?.data.url}
          loading="eager"
          onLoad={() => setIsLoading(false)}
          onError={() => setIsLoading(false)}
        />
      )}
    </Modal>
  );
};
