import { Button } from '@shared/components/Button';
import { Icon } from '@shared/components/Icon';
import { useGetS3PutObject } from '@shared/data/practice/hooks';

import { Accessor, IAttachment } from '../../../shared/data/types';

interface CheckoutAttachmentModuleProps {
  attachments?: IAttachment[];
}

export const CheckoutAttachmentModule = ({ attachments }: CheckoutAttachmentModuleProps) => {
  const { mutate } = useGetS3PutObject(Accessor.PATIENT);

  const handleOnClick = (serverFilename: string, clientFilename: string) => {
    mutate(
      { serverFilename },
      {
        onSuccess: ({ data: { url } }) => {
          const link = document.createElement('a');

          link.href = url;
          link.rel = 'noopener noreferrer';

          if (!iOSSafari) {
            link.target = '_blank';
            link.download = clientFilename;
          }

          // Append the link to the document body and trigger the download
          document.body.appendChild(link);
          link.click();

          // Clean up by revoking the object URL and removing the link element
          URL.revokeObjectURL(link.href);
          document.body.removeChild(link);
        },
      },
    );
  };

  return (
    <>
      {attachments &&
        attachments.length > 0 &&
        attachments?.map(({ clientFilename, serverFilename }) =>
          clientFilename && serverFilename ? (
            <div
              key={serverFilename}
              className="bg-primary-light py-5 md:py-4 px-4 flex md:flex-row gap-4 md:gap-6 items-center rounded-2xl w-full md:mt-4"
            >
              <span className="w-6">
                <Icon size={24} className="stroke-primary-main" name="file-text" />
              </span>
              <Button
                aria-label="download-attached-file"
                variant="navlink"
                text={clientFilename}
                onClick={() => handleOnClick(serverFilename, clientFilename)}
                className="text-primary-dark [&>span]:!font-normal !p-0 active:border focus:outline-none [&>span]:underline-offset-2 [&>span]:text-left [&>span]:break-all"
                testId="download-btn"
              />
            </div>
          ) : null,
        )}
    </>
  );
};

const ua = window.navigator.userAgent;
const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
const webkit = !!ua.match(/WebKit/i);
const iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
