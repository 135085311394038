import { MobileNavigation } from '@patient/templates/Navigation/MobileNavigation';
import { Navigation } from '@patient/templates/Navigation/Navigation';
import { useMediaQuery } from '@shared/hooks';

export const LoggedInNav = ({ patientName, logoUrl }: { patientName: string; logoUrl: string }) => {
  const isDesktop = useMediaQuery('min-width: 768px');

  return isDesktop ? (
    <Navigation patientName={patientName} logoUrl={logoUrl} />
  ) : (
    <MobileNavigation logoUrl={logoUrl} />
  );
};
