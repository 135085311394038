import { Dispatch, SetStateAction } from 'react';
import { Icon, Paragraph } from '@library/components/atoms';
import { Checkbox, Option } from '@library/components/molecules';
import { Size } from '@library/types';
import { getTextSize } from '@library/utils';
import clsx from 'clsx';

export const menuWrapperClasses = `
  absolute mt-2.5 border border-borderNeutralSoft rounded-lg bg-white z-10 max-h-[23.25rem] w-[16.625rem] grid grid-cols-1 py-2 overflow-hidden overflow-y-scroll
`;
export const menuItemWrapperClasses = `
  px-4 text-left !cursor-pointer w-full text-foregroundNeutralPrimary flex items-center hover:bg-foregroundLightPrimaryHover',
`;

export const menuItemPadding = (size: Size) => {
  switch (size) {
    case 'small':
      return 'py-2.5';
    case 'medium':
      return 'py-3';
    case 'large':
      return 'py-4';
  }
};

export interface SelectorMenuProps {
  testId?: string;
  alignment?: 'left' | 'right';
  setHighlightedIndex: Dispatch<SetStateAction<number | null>>;
  handleOptionClick: (val: number) => void;
  highlightedIndex: number | null;
  selectedIndex: number | null;
  selectedOptions?: Option[];
  options: Option[];
  size: 'small' | 'medium' | 'large';
  isMultiSelect?: boolean;
  errorTitle?: string;
  errorDescription?: string;
  className?: string;
  hideScrollbar?: boolean;
  fullWidth?: boolean;
}

export const SelectorMenu = ({
  testId,
  alignment,
  options,
  highlightedIndex,
  handleOptionClick,
  setHighlightedIndex,
  selectedIndex,
  selectedOptions,
  size,
  isMultiSelect,
  errorTitle,
  errorDescription,
  className = '',
  hideScrollbar,
  fullWidth,
}: SelectorMenuProps) => {
  const setAlignment = (alignment?: 'left' | 'right') => {
    switch (alignment) {
      case 'left':
        return 'left-0';
      case 'right':
        return 'right-0';
      default:
        return 'right-0';
    }
  };
  return (
    <ul
      data-testid={`${testId}-dropdown`}
      id="dropdown-listbox"
      role="listbox"
      className={clsx(
        menuWrapperClasses,
        setAlignment(alignment),
        className,
        hideScrollbar && 'no-scrollbar',
        fullWidth && '!w-full',
      )}
      aria-activedescendant={highlightedIndex !== null ? `dropdown-option-${highlightedIndex}` : undefined}
    >
      {options.map((option, index) => (
        <li
          className="w-full"
          key={`dropdow-option-${option.value}`}
          role="option"
          id={`dropdown-option-${index}`}
          aria-selected={highlightedIndex === index}
        >
          <button
            className={clsx(
              menuItemWrapperClasses,
              highlightedIndex === index && 'bg-foregroundLightPrimaryHover',
              menuItemPadding(size),
            )}
            onMouseEnter={() => setHighlightedIndex(index)}
            onClick={(event) => {
              event.preventDefault();
              handleOptionClick(index);
              event.stopPropagation();
            }}
            data-testid={`selector-option-${option.name}`}
          >
            {isMultiSelect ? (
              <Checkbox
                className="pointer-events-none"
                checkboxSize={size}
                labelText={option.name}
                checked={selectedOptions?.some((val) => val.value === option.value || false)}
                onChange={(event) => event.preventDefault()}
                value={option.value}
                name={option.name}
              />
            ) : (
              <div className="w-full flex justify-between items-start gap-x-2">
                <div className="flex flex-col gap-1">
                  <span className={clsx(getTextSize(size), 'truncate text-foregroundNeutralPrimary')}>
                    {option.name}
                  </span>
                  {option.helperText && (
                    <span className={clsx(getTextSize(size), 'text-foregroundNeutralSecondary')}>
                      {option.helperText}
                    </span>
                  )}
                </div>
                {index === selectedIndex && (
                  <span className="ml-auto">
                    <Icon iconName="check" size="small" />
                  </span>
                )}
              </div>
            )}
          </button>
          {options.length - 1 !== index && (
            <span className="px-4 block">
              <div className="border-borderNeutralSoft border-b h-[1px]" />
            </span>
          )}
        </li>
      ))}
      {!options.length && (errorTitle || errorDescription) && (
        <div className="px-4 py-2 flex gap-1 flex-col">
          {errorTitle && <Paragraph size="medium">{errorTitle}</Paragraph>}
          {errorDescription && (
            <Paragraph className="!text-foregroundNeutralSecondary" size="small">
              {errorDescription}
            </Paragraph>
          )}
        </div>
      )}
    </ul>
  );
};
