import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { t } from 'i18next';

import { CheckoutPaymentOptions } from '@patient/components/CheckoutPaymentOptions';
import { AddressForm } from '@patient/pages/TreatmentPage/TreatmentPlanPage/PaymentMethod/Forms/AddressForm';
import { TreatmentNames } from '@practice/pages/PatientsPages/PatientsDashboard/PatientTable/types';
import { Button } from '@shared/components/Button';
import { Typography } from '@shared/components/Typography';
import { usePatchTreatmentGuides, usePostDividebuyRedirect } from '@shared/data/practice/hooks';
import {
  Accessor,
  IPaymentMethod,
  ITreatment,
  ITreatmentGuideDecision,
  NotGoingAheadReason,
  PatientDecision,
} from '@shared/data/types';
import { usePatientPracticeStore } from '@shared/stores/patientPracticeStore';
import { PatientMixpanel } from '@shared/utils/patientMixpanel';

import { CheckoutNotGoingAheadOptions } from '../CheckoutNotGoingAheadOptions';
import { CheckoutPatientDecisionOptions } from '../CheckoutPatientDecisionOptions';

interface ProceedWithTreatmentModuleProps {
  financeEnabled: boolean;
  treatmentGuideId: string;
  refetchTreatmentGuide: () => void;
  treatments?: ITreatment[];
  treatmentPrice?: number;
  practiceName: string;
  setDisplayDecisionOptions: (display: boolean) => void;
  goAheadCheckedDefault?: boolean;
  financeOnly: boolean;
  displayPricing?: boolean;
  enablePayment?: boolean;
}

interface ProceedWithTreatmentCTA {
  paymentMethod?: IPaymentMethod;
  patientDecision?: PatientDecision;
  onClick: () => void;
  disabled: boolean;
}

const ProceedWithTreatmentCTA = ({ patientDecision, paymentMethod, onClick, disabled }: ProceedWithTreatmentCTA) => {
  if (patientDecision) {
    if (patientDecision === PatientDecision.IM_NOT_SURE) {
      return <Button testId="confirm" text="Confirm" onClick={onClick} />;
    }
    if (patientDecision === PatientDecision.ID_LIKE_TO_GOAHEAD) {
      return (
        <Button
          testId="confirm"
          text={paymentMethod === IPaymentMethod.FINANCE ? 'Continue with DivideBuy' : 'Confirm'}
          onClick={onClick}
          disabled={disabled}
        />
      );
    }
    return <Button testId="confirm" text="Confirm" onClick={onClick} disabled={disabled} />;
  }
  return <Button testId="confirm" text="Confirm" disabled />;
};

export const ProceedWithTreatmentModule = ({
  financeEnabled,
  refetchTreatmentGuide,
  treatmentGuideId,
  treatments,
  treatmentPrice,
  practiceName,
  setDisplayDecisionOptions,
  goAheadCheckedDefault,
  financeOnly,
  enablePayment,
  displayPricing,
}: ProceedWithTreatmentModuleProps) => {
  const [patientDecision, setPatientDecision] = useState<PatientDecision | undefined>();
  const [notGoingAheadReason, setNotGoingAheadReason] = useState<NotGoingAheadReason | undefined>();
  const [paymentMethod, setPaymentMethod] = useState<IPaymentMethod | undefined>();
  const [redirectIsLoading, setRedirectLoading] = useState(false);

  const { practices } = usePatientPracticeStore();

  useEffect(() => {
    if (goAheadCheckedDefault)
      setPatientDecision(enablePayment ? PatientDecision.ID_LIKE_TO_GOAHEAD : PatientDecision.IM_INTERESTED);
  }, [goAheadCheckedDefault, enablePayment]);

  const patchTreatmentGuide = usePatchTreatmentGuides(Accessor.PATIENT, {
    onSuccess: () => {
      refetchTreatmentGuide();
      setDisplayDecisionOptions(false);
    },
  });

  const { mutate: financeRedirectMutation } = usePostDividebuyRedirect({
    onSuccess: (data: { data: { url: string } }) => {
      if (treatments && data) {
        PatientMixpanel.track('Visited DivideBuy', {
          'Practice name': practices?.[0]?.name,
          'Treatment name':
            treatments ||
            []
              .map(({ treatmentDefinition }) => TreatmentNames[treatmentDefinition as keyof typeof TreatmentNames])
              .join(', '),
          'Treatment price': treatmentPrice,
        });
      }
      setRedirectLoading(false);
      window.location.replace(data.data.url);
    },
  });

  const trackDecision = useCallback(
    (eventProperties: Record<string, string>) => {
      PatientMixpanel.track('Patient made a decision on treatment', {
        'Practice name': practiceName,
        'Treatment name':
          treatments ||
          []
            .map(({ treatmentDefinition }) => TreatmentNames[treatmentDefinition as keyof typeof TreatmentNames])
            .join(', '),
        'Treatment price': treatmentPrice,
        ...eventProperties,
      });
    },
    [practiceName, treatments, treatmentPrice],
  );

  const handleSetDecision = (event: ChangeEvent<HTMLInputElement>) => {
    setPatientDecision(event.target.value as PatientDecision);
    // reset any previous values when decision changes
    if (paymentMethod) setPaymentMethod(undefined);
    if (notGoingAheadReason) setNotGoingAheadReason(undefined);
  };

  const handlePaymentMethod = (paymentMethod: IPaymentMethod) => {
    setPaymentMethod(paymentMethod);
  };

  const handleNotGoingAhead = (event: ChangeEvent<HTMLInputElement>) => {
    setNotGoingAheadReason(event.target.value as NotGoingAheadReason);
  };

  const handleOnConfirm = () => {
    switch (patientDecision) {
      case PatientDecision.ID_LIKE_TO_GOAHEAD:
        if (paymentMethod === IPaymentMethod.PAY_IN_PRACTICE) {
          patchTreatmentGuide.mutate(
            {
              id: treatmentGuideId,
              decision: ITreatmentGuideDecision.ACCEPTED,
              reason: null,
            },
            {
              onSuccess: () => {
                trackDecision({
                  'Decision': patientDecision,
                  'Payment method': 'Pay in practice',
                });
              },
            },
          );
        } else {
          patchTreatmentGuide.mutate(
            { id: treatmentGuideId, decision: ITreatmentGuideDecision.INTERESTED, reason: null },
            {
              onSuccess: () => {
                trackDecision({
                  Decision: patientDecision,
                });
              },
            },
          );
        }
        break;
      case PatientDecision.IM_INTERESTED:
        patchTreatmentGuide.mutate(
          { id: treatmentGuideId, decision: ITreatmentGuideDecision.INTERESTED, reason: null },
          {
            onSuccess: () => {
              trackDecision({
                Decision: patientDecision,
              });
            },
          },
        );
        break;
      case PatientDecision.IM_NOT_SURE:
        patchTreatmentGuide.mutate({
          id: treatmentGuideId,
          decision: ITreatmentGuideDecision.UNSURE,
          reason: null,
        });
        trackDecision({
          Decision: patientDecision,
        });
        break;

      case PatientDecision.I_DONT_WANT_TO_GO_AHEAD:
        patchTreatmentGuide.mutate({
          id: treatmentGuideId,
          decision: ITreatmentGuideDecision.DECLINED,
          reason: notGoingAheadReason,
        });
        if (notGoingAheadReason) {
          trackDecision({
            'Decision': patientDecision,
            'Not ready reason': notGoingAheadReason,
          });
        }
        break;
    }
  };

  return (
    <div className="flex flex-col gap-6">
      {(!financeOnly || !treatmentPrice) && (
        <>
          <Typography variant="h6">How would you like to proceed?</Typography>

          <CheckoutPatientDecisionOptions
            enablePayment={enablePayment}
            price={treatmentPrice}
            onChange={handleSetDecision}
            goAheadCheckedDefault={goAheadCheckedDefault}
          />
        </>
      )}
      {patientDecision === PatientDecision.ID_LIKE_TO_GOAHEAD &&
        treatmentPrice &&
        enablePayment !== false &&
        displayPricing && (
          <>
            {!financeOnly && <hr className="w-full" />}
            <CheckoutPaymentOptions
              financeOnly={financeOnly}
              financeEnabled={financeEnabled}
              onChange={handlePaymentMethod}
            />
            {paymentMethod === 'FINANCE' && (
              <>
                <div className="flex flex-col gap-4">
                  <Typography variant="b14" className="text-grey-600">
                    {t('patient.checkoutPage.paymentOptions.financeRedirect.1')}
                  </Typography>
                  <Typography variant="b14" className="text-grey-600 !mb-0">
                    {t('patient.checkoutPage.paymentOptions.financeRedirect.2')}
                  </Typography>
                  <Typography variant="b14" className="text-grey-600">
                    {t('patient.checkoutPage.paymentOptions.financeRedirect.3')}
                  </Typography>
                </div>
                <AddressForm
                  treatmentGuideId={treatmentGuideId}
                  financeRedirectMutation={financeRedirectMutation}
                  setRedirectIsLoading={setRedirectLoading}
                  redirectIsLoading={redirectIsLoading}
                />
              </>
            )}
          </>
        )}
      {patientDecision === PatientDecision.I_DONT_WANT_TO_GO_AHEAD && (
        <>
          <hr className="w-full" />
          <CheckoutNotGoingAheadOptions
            onChange={handleNotGoingAhead}
            hasPrice={Boolean(treatmentPrice && (treatmentPrice as number) > 0 && displayPricing)}
            financeEnabled={financeEnabled}
            displayPricing={displayPricing}
          />
        </>
      )}
      {paymentMethod !== 'FINANCE' && (
        <ProceedWithTreatmentCTA
          patientDecision={patientDecision}
          disabled={
            !(
              Boolean(paymentMethod || patientDecision === PatientDecision.IM_INTERESTED) ||
              notGoingAheadReason ||
              (enablePayment === false && displayPricing === false) ||
              !enablePayment
            )
          }
          onClick={handleOnConfirm}
        />
      )}
    </div>
  );
};
