import { useEffect, useMemo, useState } from 'react';
import { t } from 'i18next';

import { CheckoutAttachmentModule } from '@patient/components/CheckoutAttachmentModule';
import { CheckoutFinanceOptions } from '@patient/components/CheckoutFinanceOptions';
import { CheckoutPriceModule } from '@patient/components/CheckoutPriceModule';
import DentistNotes from '@patient/components/DentistNotes';
import { AccordianItem } from '@shared/components/CheckoutAccordian/CheckoutAccordian';
import { useGetTreatmentDefinition } from '@shared/data/practice/hooks';
import { Accessor, ITreatmentDefinition, ITreatmentGuide, PricingType } from '@shared/data/types';
import { useMediaQuery } from '@shared/hooks';

type Props = {
  treatmentGuideData: ITreatmentGuide | undefined;
  financeEnabled: boolean;
  practiceName?: string;
};
export const useGetCheckoutAccordionItems = ({ treatmentGuideData, financeEnabled, practiceName }: Props) => {
  const [accordionItems, setAccordionItems] = useState<AccordianItem[]>([]);
  const isDesktop = useMediaQuery('min-width: 1280px');
  const { data: treatmentDefinitions } = useGetTreatmentDefinition(undefined, Accessor.PATIENT, true);

  const treatments = useMemo(
    () =>
      treatmentGuideData?.treatments
        ? treatmentGuideData.treatments.map(
            (treatment) =>
              ((treatmentDefinitions?.data as ITreatmentDefinition[]) || []).find(
                ({ id }) => id === treatment.treatmentDefinition,
              )?.name || '',
          )
        : [],
    [treatmentGuideData, treatmentDefinitions],
  );

  useEffect(() => {
    if (!treatmentGuideData) return;
    const { notes, attachments, price, deposit, displayNotes, displayPricing, displayFile, pricingType } =
      treatmentGuideData;
    const items: AccordianItem[] = [];

    // Notes section
    if (notes && displayNotes !== false) {
      items.push({
        title: t('patient.checkoutPage.accordion.dentistNotesTitle'),
        description: <DentistNotes notes={notes} />,
        defaultOpen: isDesktop,
      });
    }

    //Attachments
    if (attachments && displayFile !== false) {
      items.push({
        title: t('patient.checkoutPage.accordion.fileTitle'),
        description: <CheckoutAttachmentModule attachments={attachments} />,
        defaultOpen: isDesktop,
      });
    }

    // Price section
    if (price && displayPricing) {
      items.push({
        title: t('patient.checkoutPage.accordion.priceTitle'),
        description: (
          <CheckoutPriceModule
            price={parseInt(price)}
            deposit={deposit}
            enablePayment={displayPricing && pricingType === PricingType.FINAL}
          />
        ),
        defaultOpen: true,
      });
    }

    // Finance options section
    if (displayPricing && price && financeEnabled) {
      items.push({
        title: t('patient.checkoutPage.accordion.financeOptionsTitle'),
        description: (
          <CheckoutFinanceOptions
            displayPricing={displayPricing}
            price={parseInt(price)}
            deposit={parseInt(deposit || '0')}
            practiceName={practiceName}
            treatments={treatments}
          />
        ),
        defaultOpen: true,
      });
    }

    setAccordionItems(items);
  }, [treatmentGuideData, financeEnabled, isDesktop, practiceName, treatments]);
  return accordionItems;
};
