export const arrowAlignmentObject = {
  top: 'top-8 ',
  left: 'left-8 ',
  right: 'right-8 ',
  center: '',
};

export const arrowPositionObject = {
  top: 'inset-y-full -ml-1.5 -mt-[5px] border-t-0 border-l-0',
  left: '-ml-[5px] inset-x-full border-b-0 border-l-0',
  right: '-ml-[7px] inset-x-0 border-t-0 border-r-0',
  bottom: 'inset-y-0 -ml-1.5 -mt-[7px] border-b-0 border-r-0',
};
export const getArrowClasses = (
  position: 'top' | 'bottom' | 'left' | 'right',
  alignment?: 'top' | 'left' | 'right' | 'center' | undefined,
) => {
  return alignment
    ? `${arrowAlignmentObject[alignment]} ${arrowPositionObject[position!]}`
    : `${arrowPositionObject[position!]}`;
};
