import { Button } from '@shared/components/Button';
import { Icon } from '@shared/components/Icon';
import { Typography } from '@shared/components/Typography';
import { copyInfo } from '@shared/utils/copyInfo';

interface CheckoutNotSureConfirmationProps {
  practiceName: string;
  practiceNumber: string;
  email: string;
  setDisplayDecisionOptions: (val: boolean) => void;
  patientHasDeclined: boolean;
}

export const CheckoutNotSureConfirmation = ({
  practiceName,
  practiceNumber,
  email,
  setDisplayDecisionOptions,
  patientHasDeclined,
}: CheckoutNotSureConfirmationProps) => {
  const handleOnclick = () => {
    setDisplayDecisionOptions(true);
  };
  return (
    <div className="flex flex-col gap-6 bg-primary-surface" data-testid="not-sure-confirmation">
      <Typography variant="h6">
        {patientHasDeclined
          ? 'Thanks for letting us know you don’t want to go ahead.'
          : 'Thanks for letting us know you were not sure about proceeding.'}
      </Typography>
      <div>
        <Typography variant="b14">Questions?</Typography>
        <Typography variant="h6">Contact {practiceName}</Typography>
      </div>
      <div className="flex flex-col gap-4">
        <div className="flex gap-6 bg-white rounded-md p-4">
          <Icon className="stroke-accent1-900 stroke-2" size={24} name="phone" />
          {practiceNumber}
        </div>
        <div className="flex gap-6 bg-white rounded-md p-4 items-center">
          <span className="w-6">
            <Icon className="stroke-accent1-900 stroke-2" size={24} name="mail" />
          </span>
          <div className="w-[100% - 10rem] break-all">{email}</div>
          <button className="w-6 inline" onClick={() => copyInfo(email)}>
            <Icon name="copy" className="stroke-primary-main active:bg-gray-400 rounded stroke-2" />
          </button>
        </div>
      </div>
      <hr />
      <Typography variant="h6">Changed your mind?</Typography>
      <Button testId="cta" text="Choose another option" onClick={handleOnclick} />
    </div>
  );
};
