import { t } from 'i18next';

import { Typography } from '@shared/components/Typography';
import { currencyFormatter } from '@shared/utils/numberFormatter';

export const CheckoutPriceModule = ({
  price,
  deposit,
  enablePayment,
}: {
  price: number;
  deposit?: string | null;
  enablePayment?: boolean;
}) => {
  const finalBalance = price! - parseInt(deposit || '0');

  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-between">
        <Typography variant="b16" className="text-primary-dark">
          {enablePayment
            ? t('practice.treatmentGuidePage.form.pricingDetails.totalPrice')
            : t('practice.treatmentGuidePage.form.pricingDetails.estimatedTotalPrice')}
        </Typography>
        <Typography data-testid="price" variant="b16" className="text-primary-dark">
          {currencyFormatter(price, 2)}
        </Typography>
      </div>

      {!!deposit && (
        <>
          <hr />
          <div className="flex justify-between">
            <Typography variant="b16" className="text-primary-dark">
              {enablePayment
                ? t('practice.treatmentGuidePage.form.pricingDetails.deposit')
                : t('practice.treatmentGuidePage.form.pricingDetails.estimatedDeposit')}
            </Typography>
            <Typography data-testid="deposit" variant="b16" className="text-primary-dark">
              {currencyFormatter(parseInt(deposit), 2)}
            </Typography>
          </div>
          <hr />
          <div className="flex justify-between">
            <Typography variant="b16" className="text-primary-dark">
              {enablePayment
                ? t('practice.treatmentGuidePage.form.pricingDetails.paymentSummary.remainingPrice')
                : t('practice.treatmentGuidePage.form.pricingDetails.paymentSummary.estimatedRemainingPrice')}
            </Typography>
            <Typography data-testid="final-price" variant="b16" className="text-primary-dark">
              {currencyFormatter(finalBalance, 2)}
            </Typography>
          </div>
        </>
      )}
    </div>
  );
};
