import { forwardRef, useState } from 'react';
import { ErrorText, Icon } from '@library/components/atoms';
import { InputProps, Label } from '@library/components/molecules';
import { errorClasses, inputClasses, inputDisabledClasses } from '@library/components/molecules/Input/classes';
import { getInputSizeClasses } from '@library/components/molecules/Input/utils';
import clsx from 'clsx';

interface PasswordProps extends InputProps {
  showValidation?: boolean;
}
export const PasswordInput = forwardRef<HTMLInputElement, PasswordProps>(
  (
    {
      id,
      testId,
      autoComplete,
      inputSize,
      labelText,
      labelSize = 'medium',
      showValidation,
      helperText,
      errorText,
      ...props
    },
    ref,
  ) => {
    const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);
    const sizeClasses = getInputSizeClasses(inputSize);

    return (
      <div className="flex flex-col gap-[0.625rem] relative">
        {(labelText || helperText) && (
          <Label
            htmlFor={props.name}
            disabled={props.disabled}
            appearance="secondary"
            labelText={labelText}
            helperText={helperText}
            size={labelSize}
            labelStrong={false}
          />
        )}
        <div className="flex flex-col gap-[0.625rem] relative">
          <div className="relative">
            <span>
              <input
                ref={ref}
                id={id}
                data-testid={testId}
                type={isPasswordVisible ? 'text' : 'password'}
                required
                disabled={props.disabled}
                autoComplete={autoComplete}
                placeholder={props.placeholder}
                className={clsx(
                  inputClasses,
                  sizeClasses,
                  props.disabled && inputDisabledClasses,
                  errorText && errorClasses,
                  inputSize === 'small' ? 'pr-8' : 'pr-10',
                )}
                {...props}
              />
            </span>
            <div
              data-testid="password-eye-icon"
              className="absolute inset-y-0 right-1 flex items-center pr-3 cursor-pointer"
              onClick={() => setIsPasswordVisible((state) => !state)}
            >
              {isPasswordVisible ? (
                <Icon size="medium" iconName="eye-closed" className="text-foregroundNeutralQuaternary" />
              ) : (
                <Icon size="medium" iconName="eye" className="text-foregroundNeutralQuaternary" />
              )}
            </div>
          </div>
          {errorText && showValidation && <ErrorText icon="info" text={errorText} />}
        </div>
      </div>
    );
  },
);

PasswordInput.displayName = 'PasswordInput';
