import { memo, ReactNode } from 'react';
import { BadgeAppearance } from '@library/components/atoms';
import { IconName } from '@library/components/atoms/Icon/utils';
import { BaseHelper, BaseLabel } from '@library/components/molecules';
import { Size } from '@library/types';

export interface LabelProps extends React.HTMLAttributes<HTMLLabelElement> {
  htmlFor?: string;
  labelText?: string;
  helperText?: string;
  appearance?: 'primary' | 'secondary' | 'tertiary';
  size: Size;
  labelIcon?: IconName;
  labelBadge?: string;
  helperIcon?: IconName;
  helperBadge?: string;
  disabled?: boolean;
  labelStrong?: boolean;
  labelBadgeAppearance?: BadgeAppearance;
  tooltip?: ReactNode;
  testId?: string;
  truncate?: boolean;
}

export const Label = memo(
  ({
    htmlFor,
    labelText,
    helperBadge,
    helperText,
    helperIcon,
    labelIcon,
    labelBadge,
    appearance = 'primary',
    size,
    disabled,
    labelStrong = true,
    labelBadgeAppearance,
    tooltip,
    testId,
    truncate,
    ...props
  }: LabelProps): JSX.Element => {
    return (
      <label data-testid={testId} htmlFor={htmlFor} {...props}>
        {labelText && (
          <BaseLabel
            className={truncate ? '[&>span]:truncate' : ''}
            text={labelText}
            labelIcon={labelIcon}
            badgeText={labelBadge}
            size={size}
            disabled={disabled}
            strong={labelStrong}
            badgeAppearance={labelBadgeAppearance}
            tooltip={tooltip}
          />
        )}
        {helperText && (
          <BaseHelper
            appearance={appearance}
            badgeText={helperBadge}
            helperIcon={helperIcon}
            text={helperText}
            size={size}
            disabled={disabled}
          />
        )}
      </label>
    );
  },
);

Label.displayName = 'Label';
