import { ChangeEvent, forwardRef, InputHTMLAttributes } from 'react';
import clsx from 'clsx';

import { Icon, IconNameType } from '@shared/components/Icon';

import { Typography } from '../Typography';

export const inputBaseClasses =
  'block w-full appearance-none text-base rounded-lg border px-3 py-3 bg-transparent focus:outline-none';

export const inputClasses =
  'border-primary-dark text-gray-900 placeholder-gray-400 focus:border-primary-main focus:ring-primary-main';

export const inputErrorClasses =
  'border-state-error text-state-error placeholder-state-error focus:border-state-error focus:ring-state-error';

type LabelProps = {
  id: string;
  children: React.ReactNode;
  required?: boolean;
  className?: string;
};

type FormErrorProps = {
  error?: string;
};

type FormGroupProps = {
  children: React.ReactNode;
  className?: string;
  hasLabel: boolean;
  inputClassName?: string;
};

export const Label = ({ id, children, required, className }: LabelProps) => {
  return (
    <>
      <Typography className={clsx('inline-block', className || 'mb-2')} element="label" variant="l16" htmlFor={id}>
        {children}
      </Typography>
      {required && <span className="text-primary-main">*</span>}
    </>
  );
};

export const FormError = ({ error }: FormErrorProps) => {
  return error ? (
    <span className="relative mt-2 bottom-0 flex items-center">
      <Icon name="x" size={16} className="stroke-state-error" />
      <Typography variant="l12">{error}</Typography>
    </span>
  ) : null;
};

export const FormGroup = ({ children, className, hasLabel }: FormGroupProps) => (
  <div className={clsx(className, hasLabel ? 'h-fit min-h-[6rem]' : 'h-[5rem]', 'relative mb-2')}>{children}</div>
);

export interface TextFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  id: string;
  inputClassName?: string;
  testId?: string;
  label?: string;
  error?: string;
  iconRightName?: IconNameType;
  showValidationTips?: boolean;
  validationText?: string;
  hideError?: boolean;
  hideAsterisk?: boolean;
  preventNumericValues?: boolean;
}

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      id,
      label,
      type = 'text',
      className = '',
      inputClassName = '',
      required,
      name,
      autoComplete,
      disabled = false,
      placeholder,
      error,
      iconRightName,
      testId = id,
      onChange,
      maxLength,
      hideError,
      preventNumericValues,
      ...props
    },
    ref,
  ) => {
    const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
      if (preventNumericValues) {
        event.target.value = event.target.value.replace(/[0-9]/g, '');
        onChange?.(event);
      } else {
        onChange?.(event);
      }
    };
    return (
      <FormGroup className={className} hasLabel={Boolean(label)}>
        {label && (
          <Label id={id} required={required}>
            {label}
          </Label>
        )}
        <div className="relative">
          <input
            ref={ref}
            id={id}
            data-testid={testId}
            name={name}
            type={type}
            required={required}
            disabled={disabled}
            autoComplete={autoComplete}
            placeholder={placeholder}
            className={clsx(inputClassName, inputBaseClasses, error && inputErrorClasses, !error && inputClasses)}
            onChange={handleOnChange}
            maxLength={maxLength}
            {...props}
          />
          {iconRightName && (
            <div className="pointer-events-none absolute inset-y-0 right-1 flex items-center pr-3">
              <Icon name={iconRightName} />
            </div>
          )}
        </div>
        {!hideError && <FormError error={error} />}
      </FormGroup>
    );
  },
);

TextField.displayName = 'TextField';
