import { t } from 'i18next';

import { Typography } from '@shared/components/Typography';
import { DivideBuyInstalment } from '@shared/data/types';

export const Disclaimer = ({
  instalments,
  repExample,
}: {
  repExample?: DivideBuyInstalment;
  instalments?: DivideBuyInstalment[];
}) => {
  const { offersRegulated, offersUnregulated } = {
    offersRegulated: (instalments || []).find(({ type }) => type === 'ibc'),
    offersUnregulated: (instalments || []).find(({ type }) => type === 'ifc'),
  };
  return (
    <div>
      <div className="flex flex-col gap-4 mx-auto">
        {offersUnregulated && (
          <Typography variant="b12" className="text-grey-800" element="span">
            <>
              <strong>{t('patient.treatmentPlanPage.paymentPage.instalments.unregulated.title')}</strong>&nbsp;
              {t('patient.treatmentPlanPage.paymentPage.instalments.unregulated.text', { ...repExample })}
            </>
          </Typography>
        )}
        {offersRegulated && (
          <Typography variant="b12" className="text-grey-800" element="span">
            <>
              <strong>{t('patient.treatmentPlanPage.paymentPage.instalments.regulated.title')}</strong>&nbsp;
              {t('patient.treatmentPlanPage.paymentPage.instalments.regulated.text', { ...repExample })}
            </>
          </Typography>
        )}

        <Typography variant="b12" className="text-grey-800" element="span">
          {t('patient.treatmentPlanPage.paymentPage.legalText')}
        </Typography>
      </div>
    </div>
  );
};
