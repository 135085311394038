import { captureMessage } from '@sentry/react';
interface HandleReactQueryErrorsArgs {
  status: number;
  errorMessage: string;
  deviceId: string;
  secret: string;
  url?: string;
}

const MAGIC_LINK_INVALID = 'MAGIC_LINK_INVALID';
const MAGIC_LINK_EXPIRED = 'MAGIC_LINK_EXPIRED';
const MAGIC_LINK_DEVICE_MISMATCH = 'MAGIC_LINK_DEVICE_MISMATCH';

export const INVALID_LINK_MESSAGE = 'invalid-link';
export const EXPIRED_LINK_MESSAGE = 'expired-link';
export const DEVICE_MISMATCH_MESSAGE = 'device-mismatch';

export type LoginCodes = typeof INVALID_LINK_MESSAGE | typeof EXPIRED_LINK_MESSAGE | typeof DEVICE_MISMATCH_MESSAGE;

export const handleMagcLinkErrors = ({
  errorMessage,
  status,
  deviceId,
  url = 'null',
  secret,
}: HandleReactQueryErrorsArgs) => {
  switch (errorMessage) {
    case MAGIC_LINK_INVALID:
      return window.location.assign(`/patient/login?type=${INVALID_LINK_MESSAGE}`);
    case MAGIC_LINK_EXPIRED:
      return window.location.assign(`/patient/login?type=${EXPIRED_LINK_MESSAGE}`);
    case MAGIC_LINK_DEVICE_MISMATCH:
      if (url.includes('treatment-guides')) {
        captureMessage(`device mismatch: deviceId: ${deviceId}, secret: ${secret}`);
      }
      return window.location.assign(`/patient/login?type=${DEVICE_MISMATCH_MESSAGE}`);
  }
  if (status == 401) {
    return window.location.assign('/patient/login');
  }
};
