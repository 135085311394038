import { clsx } from 'clsx';

type Props = {
  items: string[];
  direction?: 'Left' | 'Right' | 'None';
  size?: 'small' | 'large';
};

export const Marquee = ({ items, direction, size = 'small' }: Props) => {
  const animation = direction === 'None' ? '' : direction === 'Right' ? 'animate-marqueeRight' : 'animate-marquee';
  const animation2 = direction === 'None' ? '' : direction === 'Right' ? 'animate-marqueeRight2' : 'animate-marquee2';
  const sizeClass =
    size === 'large'
      ? 'min-h-[9.375rem] min-w-[9.375rem] h-[9.375rem] w-[9.375rem]'
      : 'min-h-[5.75rem] min-w-[5.75rem] h-[5.75rem] w-[5.75rem]';
  return (
    <div className="w-full relative flex overflow-x-hidden">
      <div className={clsx(animation, `inline-flex whitespace-nowrap`)}>
        {items.map((item, index) => (
          <span key={`${item}-${index}`} className="mx-4">
            <img src={item} alt="logo" className={clsx(sizeClass)} />
          </span>
        ))}
      </div>
      <div className={clsx(animation2, `absolute top-0 inline-flex whitespace-nowrap`)}>
        {items.map((item, index) => (
          <span key={`${item}-${index}`} className="mx-4">
            <img src={item} alt="logo" className={clsx(sizeClass)} />
          </span>
        ))}
      </div>
    </div>
  );
};
