import { CognitoUserPool, ICognitoUserPoolData } from 'amazon-cognito-identity-js';

const CreateUserPool = (isPracticeUser: boolean) => {
  const poolData: ICognitoUserPoolData = {
    UserPoolId: isPracticeUser ? import.meta.env.VITE_PRACTICE_USER_POOL_ID : import.meta.env.VITE_PATIENT_USER_POOL_ID,
    ClientId: isPracticeUser ? import.meta.env.VITE_PRACTICE_CLIENT_ID : import.meta.env.VITE_PATIENT_CLIENT_ID,
  };

  return new CognitoUserPool(poolData);
};

export default CreateUserPool;
