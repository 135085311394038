type FormattedCurrency = (value: number, decimalPlaces?: number) => string

export const numberOptions: FormattedCurrency = (value, decimalPlaces) => {
  const options: Intl.NumberFormatOptions = {
    style: 'currency',
    currency: 'GBP',
  }
  if (decimalPlaces !== undefined) {
    options.maximumFractionDigits = decimalPlaces
    options.minimumFractionDigits = decimalPlaces
  }
  return new Intl.NumberFormat('en-GB', options).format(value)
}

export const currencyFormatter: FormattedCurrency = (value, decimalPlaces) => {
  return numberOptions(value / 100, decimalPlaces)
}

export const divideBuyCurrencyFormatter: FormattedCurrency = (value, decimalPlaces) => {
  return numberOptions(value, decimalPlaces)
}
