import { ChangeEvent } from 'react';

import { RadioButton } from '@shared/components/RadioButton';
import { PatientDecision } from '@shared/data/types';

export const CheckoutPatientDecisionOptions = ({
  onChange,
  price,
  goAheadCheckedDefault,
  enablePayment,
}: {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  price: number | undefined;
  goAheadCheckedDefault?: boolean;
  enablePayment?: boolean;
}) => {
  const labelValue = price && enablePayment ? PatientDecision.ID_LIKE_TO_GOAHEAD : PatientDecision.IM_INTERESTED;
  return (
    <div className="flex flex-col gap-6" data-testid="decision-options">
      <RadioButton
        name="decision"
        label={labelValue}
        value={labelValue}
        onChange={onChange}
        description={price ? 'Explore your options' : 'Tell us you are interested'}
        icon="thumbs-up"
        defaultChecked={goAheadCheckedDefault}
      />
      <RadioButton
        name="decision"
        label={PatientDecision.IM_NOT_SURE}
        value={PatientDecision.IM_NOT_SURE}
        onChange={onChange}
        icon="help-circle"
        description={price ? 'Let us know how we can help' : 'Your dentist can help answer any questions'}
      />
      <RadioButton
        name="decision"
        label={PatientDecision.I_DONT_WANT_TO_GO_AHEAD}
        value={PatientDecision.I_DONT_WANT_TO_GO_AHEAD}
        onChange={onChange}
        description="Tell us why"
        icon="thumbs-down"
      />
    </div>
  );
};
