import { PatientMixpanel } from './patientMixpanel';

export const trackPatientPageViews = (patientTrackingId?: string) => {
  const eventName = `Page Visit: ${window.location.pathname}${window.location.search}`;
  if (eventName.includes('/patient/dashboard') && patientTrackingId) {
    // Track logged in patient page views
    PatientMixpanel.track(eventName);
  }
  // Track all other patient page views
  else if (eventName.includes('/patient') && !eventName.includes('/practice')) {
    PatientMixpanel.track(eventName);
  }
};
