import clsx from 'clsx';

import { Icon, IconNameType } from '../Icon';
import { Spinner } from '../Spinner';
import { Typography } from '../Typography';

export type ButtonVariant = 'primary' | 'secondary' | 'tertiary' | 'outline' | 'navlink' | 'grey';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariant;
  size?: 'mini' | 'small' | 'medium' | 'large';
  text?: string;
  type?: 'button' | 'submit' | 'reset' | undefined;
  icon?: IconNameType;
  iconLeft?: IconNameType;
  iconRight?: IconNameType;
  fullWidth?: boolean;
  form?: string;
  testId?: string;
  loading?: boolean;
  iconSize?: number;
}

const commonClasses = 'group/button focus:outline';

const baseClasses =
  'leading-none transition duration-700 disabled:text-gray-500 disabled:border-gray-300 rounded-lg hover:enabled:bg-primary-main hover:enabled:text-primary-contrast';

const iconClasses = {
  mini: 'flex gap-2 items-center justify-center',
  small: 'flex gap-4 items-center justify-center',
  medium: 'flex gap-4 items-center justify-center',
  large: 'flex gap-4 items-center justify-center',
};

const variantsLookup = {
  primary:
    'bg-primary-main border border-primary-main text-primary-contrast disabled:bg-primary-light disabled:text-grey-600',
  secondary:
    'bg-accent1-900 border border-accent1-900 text-primary-contrast disabled:bg-primary-light disabled:text-grey-600',
  tertiary:
    'bg-primary-contrast border border-primary-contrast !text-primary-dark disabled:opacity-60 hover:enabled:bg-white',
  outline:
    'text-primary-dark border border-primary-dark outline-none hover:enabled:border-primary-main hover:enabled:text-primary-contrast hover:enabled:bg-primary-main rounded-xl disabled:opacity-60',
  navlink: 'center text-center underline text-primary-main font-bold p-0',
  grey: 'text-primary-dark border border-transparent bg-primary-light border-gray-300 stroke-primary-dark active:border-none focus:outline-none',
};

const iconsLookup = {
  primary: 'stroke-primary-contrast',
  secondary: 'stroke-primary-contrast',
  tertiary: 'stroke-primary-contrast',
  outline: 'stroke-primary-dark stroke-2 group-hover/button:stroke-primary-contrast',
  navlink: 'stroke-primary-main',
  grey: 'stroke-primary-dark',
};

const sizesLookup = {
  mini: 'pl-3 p-2 !rounded-lg focus:outline-1 focus:outline-offset-1 text-sm font-normal',
  small: 'p-3 focus:outline-1 focus:outline-offset-1 text-sm font-normal',
  medium: 'p-4 focus:outline-2 focus:outline-offset-2 text-base',
  large: 'py-4 px-12 focus:outline-2 focus:outline-offset-2 text-base',
};

export const Button = ({
  variant = 'primary',
  size = 'medium',
  text = '',
  icon,
  iconLeft,
  iconRight,
  fullWidth,
  form,
  testId,
  loading,
  className,
  iconSize,
  ...buttonProps
}: ButtonProps) => {
  const type = buttonProps.type || 'button';
  const { disabled } = buttonProps;
  const isIcon = iconLeft || iconRight || icon;
  const applyBaseClasses = variant !== 'outline' && variant !== 'navlink';

  const content = icon ? (
    <Icon name={icon} className={iconsLookup[variant]} />
  ) : (
    <>
      {iconLeft && <Icon name={iconLeft} className={clsx(disabled ? 'stroke-primary-dark' : iconsLookup[variant])} />}
      {loading && <Spinner inButton />}
      {!loading && (
        <Typography
          element="span"
          variant={size === 'mini' ? 'l12' : 'l16'}
          className={clsx(variant === 'navlink' && 'underline decoration-2 underline-offset-4')}
        >
          {text}
        </Typography>
      )}
      {iconRight && (
        <Icon
          size={iconSize}
          name={iconRight}
          className={clsx(disabled ? 'stroke-primary-dark' : iconsLookup[variant])}
        />
      )}
    </>
  );

  return (
    <button
      {...buttonProps}
      type={type}
      data-testid={testId}
      onClick={buttonProps.onClick}
      disabled={disabled || loading}
      form={form}
      className={clsx(
        commonClasses,
        applyBaseClasses && baseClasses,
        !loading && variantsLookup[variant],
        loading && 'bg-primary-light border border-primary-main text-primary-main',
        isIcon && iconClasses[size],
        sizesLookup[size],
        fullWidth ? 'w-full' : 'w-auto',
        className && className,
      )}
    >
      {content}
    </button>
  );
};
