import { Paragraph } from '@library/components/atoms';
import { t } from 'i18next';

import { Heading } from '@shared/components/Heading';
import { Icon } from '@shared/components/Icon';

interface Props {
  email?: string;
  phone?: string;
}

export const FinanceContactBlock = ({ email, phone }: Props) => {
  return (
    <div className="py-12 w-full flex flex-col lg:grid lg:grid-cols-2 col-span-12 lg:min-h-[420px] xl:min-h-[480px] gap-x-16 items-center">
      <div
        className="order-last lg:order-first flex flex-col gap-y-12 items-center justify-center bg-center bg-cover rounded-3xl h-[16.25rem] mx-auto w-[26rem] lg:w-full md:h-[18.75rem]"
        style={{ backgroundImage: `url(images/practice/finance-promo/financePromoPageContact.svg)` }}
      ></div>
      <div className="p-6 md:p-[3.75rem] w-full order-first lg:order-last flex flex-col items-start justify-center gap-y-6">
        <Heading variant="h2">{t('patient.financePromoPage.contactBlock.title')}</Heading>
        <Paragraph size="large" className="text-neutral-secondary">
          {t('patient.financePromoPage.contactBlock.description')}
        </Paragraph>

        <div className="flex flex-col items-center justify-center gap-y-3 w-full mt-4">
          <a
            href={`tel:${phone}`}
            className="w-full border border-neutral-subtle rounded-xl p-4 flex justify-between items-center"
          >
            <div className="flex items-center justify-start gap-4">
              <Icon name="phone" size={24} className="stroke-2 stroke-primary-main" />
              <Paragraph size="medium" className="text-neutral-primary break-all">
                {phone}
              </Paragraph>
            </div>
            <Paragraph size="medium" className="text-primary-main">
              {t('patient.financePromoPage.contactBlock.call')}
            </Paragraph>
          </a>
          <a
            href={`mailTo:${email}`}
            className="w-full border border-neutral-subtle rounded-xl p-4 gap-6 flex justify-between items-center"
          >
            <div className="flex items-center justify-start gap-4">
              <Icon name="mail" size={24} className="stroke-2 stroke-primary-main" />
              <Paragraph size="medium" className="text-neutral-primary break-all">
                {email}
              </Paragraph>
            </div>
            <Paragraph size="medium" className="text-primary-main">
              {t('patient.financePromoPage.contactBlock.email')}
            </Paragraph>
          </a>
        </div>
      </div>
    </div>
  );
};
