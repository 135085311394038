import { NavLink } from 'react-router-dom';
import { t } from 'i18next';

import { Grid } from '@shared/components/Grid';
import { LogoWatermark } from '@shared/components/Logo';
import { Typography } from '@shared/components/Typography';
import { contentUrl, routePaths } from '@shared/utils/urls';

type Props = {
  practiceName: string;
  excludeCta?: boolean;
};
export const Footer = ({ practiceName, excludeCta }: Props) => {
  return (
    <footer data-testid="footer" className="bg-accent1-900 text-white py-8 p-5 w-full">
      <Grid>
        <div className="max-w-[1312px] grid grid-cols-12 col-span-12 gap-4 place-items-start">
          <div className="flex w-full justify-start items-center gap-4 col-span-12 md:col-span-6 ">
            <LogoWatermark colour="white" width={145} className="-ml-[10px] -mr-[30px] " />|
            <Typography variant="b16" element="span" className="text-white">
              {practiceName}
            </Typography>
          </div>
          <div className="flex flex-col w-full justify-start gap-y-10 md:gap-8 items-start col-span-12 md:col-span-6 ">
            <ul className="w-full grid grid-rows-3 grid-flow-col gap-4 mt-4 md:mt-0 xl:mb-8">
              {!excludeCta && (
                <li>
                  <NavLink to={`/${routePaths.patient}/${routePaths.dashboard}`}>
                    <Typography variant="l16" element="span">
                      {t('patient.footer.links.journey')}
                    </Typography>
                  </NavLink>
                </li>
              )}
              <li>
                <NavLink to={`/${routePaths.patient}/${routePaths.dashboard}/${routePaths.support}`}>
                  <Typography variant="l16" element="span">
                    {t('patient.footer.links.support')}
                  </Typography>
                </NavLink>
              </li>
              <li>
                <NavLink to="https://www.practihealth.co.uk/patient-terms">
                  <Typography variant="l16" element="span">
                    {t('patient.footer.links.terms')}
                  </Typography>
                </NavLink>
              </li>
              <li>
                <NavLink to="https://www.practihealth.co.uk/privacy">
                  <Typography variant="l16" element="span">
                    {t('common.supportPage.patientTandCs.privacyPolicy')}
                  </Typography>
                </NavLink>
              </li>
              {!excludeCta && (
                <>
                  <li>
                    <NavLink to={`${contentUrl}/practice/cookie-declaration`}>
                      <Typography variant="l16" element="span">
                        {t('common.supportPage.patientTandCs.cookieDeclaration')}
                      </Typography>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="https://www.practihealth.co.uk/complaints-and-dispute-resolution">
                      <Typography variant="l16" element="span">
                        {t('common.supportPage.patientTandCs.complaints')}
                      </Typography>
                    </NavLink>
                  </li>
                </>
              )}
            </ul>
            <div className="flex flex-col w-full col-span-6 gap-4">
              <Typography variant="b12" element="span" className="text-white">
                {t('patient.footer.regulatoryText.0')}
              </Typography>
              <Typography variant="b12" element="span" className="text-white">
                {t('patient.footer.regulatoryText.1')}
              </Typography>
              <Typography variant="b12" element="span" className="text-white">
                {t('patient.footer.regulatoryText.2')}
              </Typography>
              <Typography variant="b12" element="span" className="text-white">
                {t('patient.footer.regulatoryText.3')}
              </Typography>
            </div>
          </div>
        </div>
      </Grid>
    </footer>
  );
};
