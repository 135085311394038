import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import clsx from 'clsx';
import * as z from 'zod';

import { Button } from '@shared/components/Button';
import { TextField } from '@shared/components/TextField';
import { PostDividebuyRedirectArgs } from '@shared/data/types';

type FormSchema = z.infer<typeof formSchema>;

const postcodeRegex = /^(([A-Za-z][A-Ha-hK-Yk-y]?[0-9][A-Za-z0-9]?) ?([0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2}))$/;

const formSchema = z.object({
  postcode: z
    .string()
    .min(1, { message: 'Postcode is required' })
    .max(10)
    .refine((v) => v.replace(/\s/g, '').toUpperCase().match(postcodeRegex) !== null),
});

type FormProps = {
  treatmentGuideId: string;
  setRedirectIsLoading: (redirectIsLoading: boolean) => void;
  financeRedirectMutation: (data: PostDividebuyRedirectArgs) => void;
  redirectIsLoading: boolean;
};

export const AddressForm = ({
  treatmentGuideId,
  financeRedirectMutation,
  setRedirectIsLoading,
  redirectIsLoading,
}: FormProps) => {
  const { control, handleSubmit, formState } = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
  });

  const onSubmit = ({ postcode }: FormSchema) => {
    setRedirectIsLoading(true);
    financeRedirectMutation({ postcode, treatmentGuideId });
  };

  return (
    <div className={clsx('flex-col gap-2')}>
      <form
        data-testid="finance-address-form"
        id="finance-address-form"
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col"
        noValidate
      >
        <Controller
          name="postcode"
          control={control}
          render={({ field }) => (
            <TextField
              id="postcode"
              required
              placeholder="Enter your postcode"
              label=""
              className="bg-white !h-fit !max-h-[3rem] !-mb-1 !rounded-xl"
              {...field}
            />
          )}
        />
        <Button
          className="mt-4"
          testId="confirm"
          text="Continue with DivideBuy"
          type="submit"
          // onClick={() => {
          //   // handleSubmit(onSubmit)
          // }}
          disabled={!formState.isValid || redirectIsLoading}
        />
      </form>
    </div>
  );
};
