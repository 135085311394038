import { forwardRef } from 'react';
import { CustomContentProps, SnackbarContent } from 'notistack';

import { Icon } from '@shared/components/Icon';
import { Typography } from '@shared/components/Typography';

export type NotistackTypes = 'success' | 'error' | 'warning';

interface ExtraProps extends CustomContentProps {
  key?: string;
  type?: NotistackTypes;
}

export const Notistack = forwardRef<HTMLDivElement, ExtraProps>(({ ...props }, ref) => {
  return (
    <SnackbarContent
      data-testid={`${props.key}-${props.type}-notice`}
      ref={ref}
      className="w-fit mt-10 p-4 pr-8 bg-primary-light rounded-lg shadow-lg"
    >
      {props.type === 'success' && <Icon name="check" size={24} className="stroke-state-success" />}
      {props.type === 'warning' && <Icon name="alert-triangle" size={24} className="stroke-state-warning" />}
      {props.type === 'error' && <Icon name="x" size={24} className="stroke-state-error" />}
      <Typography variant="b16" className="pl-4">
        {props.message}
      </Typography>
    </SnackbarContent>
  );
});

Notistack.displayName = 'notistack';
