import { Outlet } from 'react-router-dom';

import { useScript } from '@shared/hooks/useScript/useScript';

export const RoutesCookieWrapper = () => {
  useScript(
    'https://consent.cookiebot.com/uc.js',
    {
      'data-cbid': '6d613548-8d15-44e3-9ec2-7aa69ff77524',
      'data-blockingmode': 'auto',
      'data-testid': 'cookiebot-script',
    },
    { id: 'Cookiebot' },
  );
  return <Outlet />;
};
