import { lazy, MouseEvent, Suspense, useEffect, useMemo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import {
  createRoutesFromChildren,
  matchRoutes,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useNavigationType,
} from 'react-router-dom';
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { SnackbarProvider } from 'notistack';

import { Notistack } from '@shared/components/Notistack';
import { Spinner } from '@shared/components/Spinner';
const NotFoundPage = lazy(() => import('@shared/pages/NotFoundPage'));
const HomePage = lazy(() => import('@shared/pages/Home'));
import uuid from 'react-uuid';
import { captureConsoleIntegration } from '@sentry/integrations';
import * as Sentry from '@sentry/react';

import { RoutesCookieWrapper } from '@patient/components/RoutesCookieWrapper';
import PatientRoutes from '@patient/PatientRoutes';
import FinancePromoPage from '@practice/pages/FinancePromoPage';
import PracticeRoutes from '@practice/PracticeRoutes';
import { ErrorBoundaryFallback } from '@shared/components/ErrorBoundaryFallback/ErrorBoundaryFallback';
import { NotistackTypes } from '@shared/components/Notistack/Notistack';
import { ErrorCode, FetchError } from '@shared/data/types';
import { useAuth } from '@shared/stores/authStore';
import { usePatientPracticeStore } from '@shared/stores/patientPracticeStore';
import { PatientMixpanel } from '@shared/utils/patientMixpanel';
import { trackPatientPageViews } from '@shared/utils/trackPatientPageViews';

declare module 'notistack' {
  interface VariantOverrides {
    notistack: {
      testId: string;
      type: NotistackTypes;
    };
  }
}

if (
  import.meta.env.VITE_SENTRY_PROJECT_NAME === 'practi-app-test-dev' ||
  import.meta.env.VITE_SENTRY_PROJECT_NAME === 'practi-app-prod'
) {
  Sentry.init({
    ignoreErrors: [/Unable to preload CSS/],
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      captureConsoleIntegration({
        levels: ['error'],
      }),
      Sentry.browserProfilingIntegration(),
      Sentry.browserTracingIntegration({
        enableInp: true,
      }),
      Sentry.replayIntegration({
        maskAllText: true,
        blockAllMedia: false,
        networkDetailAllowUrls: [window.location.origin],
      }),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    debug: true,
    tracesSampleRate: 0.2,
    replaysSessionSampleRate: import.meta.env.VITE_SENTRY_PROJECT_NAME === 'practi-app-prod' ? 1 : 0,
    replaysOnErrorSampleRate: import.meta.env.VITE_SENTRY_PROJECT_NAME === 'practi-app-prod' ? 1 : 0,
    profilesSampleRate: 0.2,
  });
}

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

if (!localStorage.getItem('deviceId')) {
  const deviceId = uuid();
  localStorage.setItem('deviceId', deviceId);
}

export default function App() {
  const { isPracticeUser, refreshSession, trackingId: practiceTrackingId } = useAuth();
  const { trackingId: patientTrackingId } = usePatientPracticeStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (practiceTrackingId) {
      Sentry.setUser({ id: practiceTrackingId, username: `PRID: ${practiceTrackingId}` });
    }
  }, [practiceTrackingId]);

  useEffect(() => {
    if (patientTrackingId) {
      Sentry.setUser({ id: patientTrackingId, username: `PAID: ${patientTrackingId}` });
    }
  }, [patientTrackingId]);

  useEffect(() => {
    const handleButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
      const target = event.target as HTMLButtonElement;
      /**
       * Cookiebot allows the user to toggle consent for statistics, if they do so and click the 'Allow selection' button
       * or if they click the 'Allow all' or 'Allow selection' button we can track their data through Mixpanel
       * we can confirm this anywhere within the app by checking 'window.Cookiebot?.consent.statistics'
       * we also enable Sentry here
       */
      if (
        target.innerText === 'Allow all' ||
        target.innerText === 'Allow selection' ||
        target.innerText === 'Decline'
      ) {
        if (
          target.innerText === 'Allow all' ||
          (target.innerText === 'Allow selection' &&
            (document.querySelector('#CybotCookiebotDialogBodyLevelButtonStatisticsInline') as HTMLInputElement)
              ?.checked)
        ) {
          PatientMixpanel.track('Opted in for analytics');
        } else {
          PatientMixpanel.track('Opted out of analytics');
        }
      }
    };

    let elements = document.querySelectorAll('.CybotCookiebotDialogBodyButton');
    let elementArray = Array.from(elements) as HTMLButtonElement[];

    setTimeout(() => {
      elements = document.querySelectorAll('.CybotCookiebotDialogBodyButton');

      elementArray = Array.from(elements) as HTMLButtonElement[];

      elementArray.forEach((element) => {
        element.addEventListener('click', handleButtonClick as unknown as EventListener);
      });
    }, 1000);

    // Cleanup: Remove the event listeners when the component unmounts
    return () => {
      elementArray.forEach((element) => {
        element.removeEventListener('click', handleButtonClick as unknown as EventListener);
      });
    };
  }, []);

  const queryCache = useMemo(() => new QueryCache(), []);

  const mutationCache = useMemo(
    () =>
      new MutationCache({
        onError: async (error, _variables, _context) => {
          const fetchError = (error as { error: FetchError }).error;
          if (
            ![ErrorCode.ALREADY_EXISTS, ErrorCode.PRACTITIONER_EXISTS_EXCEPTION].includes(fetchError.code as ErrorCode)
          ) {
            return navigate(`/${isPracticeUser ? 'practice' : 'patient'}/error`);
          }
        },
      }),
    [navigate, isPracticeUser],
  );

  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: false,
            refetchOnWindowFocus: false,
          },
          mutations: {
            retry: false,
          },
        },
        mutationCache,
        queryCache,
      }),
    [mutationCache, queryCache],
  );

  useEffect(() => {
    const handleCookiebotOnLoad = () => {
      trackPatientPageViews(patientTrackingId);
    };

    window.addEventListener('CookiebotOnLoad', handleCookiebotOnLoad);

    return () => {
      window.removeEventListener('CookiebotOnLoad', handleCookiebotOnLoad);
    };
  });

  useEffect(() => {
    trackPatientPageViews(patientTrackingId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href]);

  useEffect(() => {
    if (window.location.href.includes('/practice')) {
      refreshSession();
    }
  }, [refreshSession]);

  return (
    <QueryClientProvider client={queryClient}>
      <SnackbarProvider
        Components={{
          notistack: Notistack,
        }}
      >
        <ErrorBoundary fallback={<ErrorBoundaryFallback />}>
          <Suspense fallback={<Spinner />}>
            <SentryRoutes>
              <Route path="/" element={<HomePage />} />
              {PracticeRoutes}
              {PatientRoutes}
              <Route path="/finance-promo" element={<RoutesCookieWrapper />}>
                <Route index element={<FinancePromoPage />} />
              </Route>
              <Route path="*" element={<NotFoundPage />} />
            </SentryRoutes>
          </Suspense>
        </ErrorBoundary>
      </SnackbarProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}
