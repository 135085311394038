import React, { forwardRef } from 'react';
import clsx from 'clsx';

import { Icon, IconNameType } from '../Icon';
import { Typography } from '../Typography';

export interface RadioButtonProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  inputHidden?: boolean;
  isPillStyle?: boolean;
  labelClass?: string;
  icon?: IconNameType;
  /** currently only for rendered when radio button has an icon prop */
  description?: string;
}

const labelPillStyle = `
  flex
  items-center
  cursor-inherit
  px-6 py-4 h-auto
`;

const labelStyle = `
  flex
  items-center
  gap-x-3
  cursor-pointer
`;

const inputStyle = `
  w-5
  h-5
  outline
  outline-1
  outline-grey-500
  active:outline-primary-main
  checked:outline-primary-main
  checked:text-primary-main
  checked:outline-offset-2
  checked:outline-2
  cursor-pointer
`;

export const RadioButton = forwardRef<HTMLInputElement, RadioButtonProps>(
  ({ icon, label, inputHidden, labelClass, description, isPillStyle = false, ...props }, ref) => (
    <Typography
      variant={labelClass ? 'b16' : 'l16'}
      element="label"
      className={clsx(isPillStyle ? labelPillStyle : labelStyle, labelClass)}
    >
      {!icon ? (
        <>
          <input {...props} ref={ref} role="radio" type="radio" className={clsx(inputHidden ? 'hidden' : inputStyle)} />
          <span className="flex gap-2 flex-col align-top">
            <Typography variant="l16" className="text-primary-dark">
              {label}
            </Typography>
            {description && (
              <Typography variant="b14" className="text-grey-600">
                {description}
              </Typography>
            )}
          </span>
        </>
      ) : (
        <span className="flex justify-between w-full">
          <span className="flex gap-2">
            <input
              {...props}
              ref={ref}
              role="radio"
              type="radio"
              className={clsx(inputHidden ? 'hidden' : inputStyle)}
            />
            <span className="flex flex-col gap-2">
              {label}
              {description && (
                <Typography variant="b14" className="text-grey-600">
                  {description}
                </Typography>
              )}
            </span>
          </span>
          <Icon className="stroke-primary-main stroke-2" name={icon} />
        </span>
      )}
    </Typography>
  ),
);

RadioButton.displayName = 'RadioButton';
