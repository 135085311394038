import { PropsWithChildren } from 'react';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';

import { Icon } from '@shared/components/Icon';
import { Typography } from '@shared/components/Typography';
import { useGetTreatmentDefinition } from '@shared/data/practice/hooks';
import { Accessor, ITreatmentDefinition, Treatment } from '@shared/data/types';

export const treatmentImages: Record<string, string> = {
  'In-practice crown': 'treatment-item-cerec-crown.jpg',
  'Dentures': 'treatment-item-dentures.jpg',
  'In-practice teeth whitening': 'treatment-item-in-practice-teeth-whitening.jpg',
  'Root canal': 'treatment-item-root-canal.jpg',
  'Dental implant': 'treatment-item-dental-implant.jpg',
  'Implant-retained dentures': 'treatment-item-Implant-retained-dentures-prod.jpg',
  'Adhesive bridge': 'treatment-item-adhesive-bridge.jpg',
  'At-home teeth whitening': 'treatment-item-at-home-teeth-whitening.jpg',
  'Veneers ': 'treatment-item-veneers.jpg',
  'Composite bonding': 'treatment-item-composite-bonding.jpg',
  'Implant-retained bridge ': 'treatment-item-implant-retained-bridge.jpg',
  'Routine hygienist': 'treatment-item-hygienist-visit.jpg',
  'Crown': 'treatment-item-crown.jpg',
  'Filling': 'treatment-item-filling.jpg',
  'Tooth extraction': 'treatment-item-tooth-extraction.jpg',
  'Fixed braces ': 'treatment-item-fixed-braces.jpg',
  'Clear aligners': 'treatment-item-clear-aligners.jpg',
  'Crown supported bridge': 'treatment-item-crown-supported-bridge.jpg',
  'Periodontal treatment ': 'treatment-item-periodontal-treatment.jpg',
  'Inlays / Onlays': 'treatment-item-inlay-onlay.jpg',
};

type OverviewCardProps = {
  treatment?: Treatment;
  treatmentName?: string;
  excludeContent?: boolean;
  viewSource?: 'practice' | 'patient';
};

export const TreatmentOverviewCard = ({
  treatment,
  treatmentName,
  excludeContent,
  viewSource,
}: OverviewCardProps & PropsWithChildren) => {
  const treatmentId = treatment?.treatmentDefinition ? treatment.treatmentDefinition : treatmentName;
  const exclude = treatment?.excludeTreatmentContent ? treatment.excludeTreatmentContent : excludeContent;

  const { data: treatmentDefinition } = useGetTreatmentDefinition(
    treatmentId,
    viewSource === 'practice' ? Accessor.PRACTICE_MEMBER : Accessor.PATIENT,
    true,
  );

  const treatmentDefinitionData = treatmentDefinition?.data as ITreatmentDefinition;

  const treatmentPath =
    viewSource === 'practice'
      ? `${window.location.origin}/practice/treatment/${treatmentId}/preview`
      : `${window.location.origin}/patient/dashboard/my-guide/${treatmentId}`;

  return (
    <div className="lg:max-h-[11rem] min-h-[11rem] bg-logo-duckegg-100 rounded-2xl flex justify-between items-center overflow-hidden">
      <div className="py-6 px-4 flex-grow">
        <Typography variant={'h5'} className="mb-2 font-bold text-primary-dark">
          {treatmentDefinitionData?.name}
        </Typography>
        <Typography variant="b16" className={clsx(!exclude ? 'mb-4' : 'mb-0', 'text-grey-800')}>
          {treatmentDefinitionData?.description}
        </Typography>
        {!exclude && (
          <NavLink
            target="_blank"
            to={treatmentPath}
            className={
              'inline-flex items-center justify-center py-3 px-6 border border-black border-solid gap-2 transition duration-700 bg-transparent rounded-lg hover:text-white hover:bg-black [&>svg]:hover:stroke-white'
            }
            data-test-id="treatment-page-link"
          >
            <Typography element="span" variant="b16" className="!font-medium">
              View treatment information
            </Typography>
            <Icon name="eye" className="stroke-black stroke-2 transition duration-700" />
          </NavLink>
        )}
      </div>
      <img
        className="hidden lg:block xl:w-[13.25rem] xl:h-[13.25rem] md:w-[11.25rem] md:h-full"
        src={`/images/treatments/overview/${treatmentImages[treatmentDefinitionData?.name]}`}
        alt="treatment"
      />
    </div>
  );
};
