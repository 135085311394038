import { Size } from '@library/types';

export const getTextSize = (size: Size) => {
  switch (size) {
    case 'xSmall':
      return 'text-[0.75rem] leading-5 tracking-[0.015rem]';
    case 'small':
      return 'text-[0.875rem] leading-5';
    case 'medium':
      return 'text-[1rem] leading-6';
    case 'large':
      return 'text-[1.125rem] leading-7';
    case 'xLarge':
      return 'text-[1.25rem] leading-8';
  }
};

export const getPaddedSize = (size: Size) => {
  switch (size) {
    case 'xSmall':
      return 'text-sm h-8 p-4';
    case 'small':
      return 'text-sm h-10 p-4';
    case 'medium':
      return 'text-base h-12 p-5';
    case 'large':
      return 'text-base h-14 p-5';
    case 'xLarge':
      return 'text-large h-16 p-5';
  }
};

export const transitionClasses = ' transition-colors duration-200 ease-out';

export const convertToCurrency = (pence: number, currency: string) => {
  return `${currency}${(pence / 100).toFixed(2)}`;
};
