import { ReactNode } from 'react';
import clsx from 'clsx';

import { Typography, TypographyProps, TypographyVariant } from '@shared/components/Typography';

type TextRendererProps = {
  text: ReactNode;
  variant?: TypographyVariant;
  /** Styles to be applied to the Typography component */
  className?: string;
  isElipsis?: boolean;
  element?: TypographyProps['element'];
};

export const elipsis = 'whitespace-nowrap overflow-hidden text-ellipsis';

export const TextRenderer = ({
  className,
  text,
  variant = 'b14',
  isElipsis = true,
  element = 'p',
}: TextRendererProps) => (
  <Typography
    element={element}
    variant={variant}
    className={clsx(isElipsis ?? elipsis, 'text-grey-800', 'leading-[unset]', className)}
  >
    {text}
  </Typography>
);
