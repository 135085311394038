import { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { Portal } from '@headlessui/react';
import { IconName } from '@library/components/atoms/Icon/utils';

import { TooltipButton } from '../TooltipButton';
import { TooltipModal } from '../TooltipModal';

import { getTooltipPosition } from './utils';

export type TooltipProps = {
  title?: string;
  supportText?: string;
  icon?: IconName;
  iconColor?: string;
  position: 'top' | 'bottom' | 'left' | 'right';
  arrow?: boolean;
};

export const Tooltip = ({
  title,
  supportText,
  icon,
  iconColor,
  position,
  children,
  arrow,
}: PropsWithChildren<TooltipProps>) => {
  const [isHovered, setIsHovered] = useState(false);
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
  const buttonRef = useRef<HTMLButtonElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isHovered && buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      const containerHeight = contentRef.current?.getBoundingClientRect().height || 0;
      const containerWidth = contentRef.current?.getBoundingClientRect().width || 0;

      const tooltipPosition = getTooltipPosition(position, containerHeight, containerWidth, rect);
      setModalPosition(tooltipPosition);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHovered]);

  return (
    <span className="relative flex">
      <TooltipButton icon={icon} color={iconColor} buttonRef={buttonRef} setIsHovered={setIsHovered}>
        {children}
      </TooltipButton>
      {isHovered && (title || supportText) && (
        <Portal>
          <div
            ref={contentRef}
            className="absolute"
            style={{
              top: modalPosition.top,
              left: modalPosition.left,
            }}
          >
            <TooltipModal position={position} title={title} supportText={supportText} arrow={arrow} />
          </div>
        </Portal>
      )}
    </span>
  );
};

Tooltip.displayName = 'Tooltip';
