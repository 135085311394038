import { createElement, HTMLAttributes } from 'react';
import clsx from 'clsx';

import { getHeadingClasses } from './utils';

const baseClasses = `
  font-medium
  text-neutral-primary
`;

export type HeadingVariant = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

export interface HeadingProps extends HTMLAttributes<HTMLHeadingElement> {
  variant: HeadingVariant;
  testId?: string;
}

export const Heading = ({ className, testId, children, variant, ...props }: HeadingProps) => {
  const sizeClasses = getHeadingClasses(variant);
  /**
   * See here for caveats when using createElement:
   * https://react.dev/reference/react/createElement#caveats
   **/
  return createElement(
    variant,
    { 'className': clsx(baseClasses, sizeClasses, className), 'data-testid': testId, ...props },
    children,
  );
};
