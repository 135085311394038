import { ChangeEvent, memo } from 'react';

import { RadioButton } from '@shared/components/RadioButton';
import { Typography } from '@shared/components/Typography';
import { IPaymentMethod } from '@shared/data/types';

interface CheckoutPaymentOptionsProps {
  onChange: (value: IPaymentMethod) => void;
  financeEnabled?: boolean;
  financeOnly?: boolean;
}

export const CheckoutPaymentOptions = memo(({ onChange, financeEnabled, financeOnly }: CheckoutPaymentOptionsProps) => {
  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => onChange(event.target.value as IPaymentMethod);
  return (
    <div className="flex flex-col gap-6">
      <Typography variant="h6">Choose how to pay</Typography>
      {!financeOnly && (
        <RadioButton
          name="payment-options"
          label="Pay in practice"
          description="Ask your dentist about payment options"
          value={IPaymentMethod.PAY_IN_PRACTICE}
          onChange={handleOnChange}
          icon="home"
        />
      )}
      {financeEnabled && (
        <RadioButton
          name="payment-options"
          label="Apply for finance"
          description="Pay monthly with DivideBuy"
          value={IPaymentMethod.FINANCE}
          onChange={handleOnChange}
          icon="pound"
        />
      )}
      {financeOnly && (
        <Typography variant="l14" className="!leading-4">
          You are also able to pay for your treatment in full. Please contact your dentist if you would like to do so.
          You may also seek alternative personal finance.
        </Typography>
      )}
    </div>
  );
});

CheckoutPaymentOptions.displayName = 'CheckoutPaymentOptions';
