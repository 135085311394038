import { DivideBuyInstalment, DividebuySubsidy } from '@shared/data/types';

export const instalmentsDataFormatter = (res: {
  data: { subsidy: DividebuySubsidy[]; [key: string]: DivideBuyInstalment | DividebuySubsidy[] };
}) => ({
  data: {
    subsidy: res.data.subsidy,
    terms: Object.values(res.data).filter((value) => !Array.isArray(value)) as DivideBuyInstalment[],
  },
});
