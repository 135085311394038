import { t } from 'i18next';

import { Icon } from '@shared/components/Icon';
import { NavButton } from '@shared/components/NavButton';
import { Typography } from '@shared/components/Typography';
import { copyInfo } from '@shared/utils/copyInfo';

interface CheckoutFinanceApprovedProps {
  practiceNumber: string;
  email: string;
  hasSeenConfirmation: boolean;
  payInPractice?: boolean;
  practiceName: string;
}

export const CheckoutPaymentConfirmed = ({
  practiceNumber,
  email,
  hasSeenConfirmation,
  payInPractice,
  practiceName,
}: CheckoutFinanceApprovedProps) => (
  <div className="flex flex-col gap-6" data-testid="payment-confirmed">
    <Typography variant="h6">
      {payInPractice
        ? t('patient.checkoutPage.financeApproved.payInPractice', { practiceName })
        : t('patient.checkoutPage.financeApproved.finance')}
    </Typography>
    <div>
      <Typography variant="b14">Next step</Typography>
      <Typography variant="h6">Book your appointment</Typography>
    </div>
    <div className="flex flex-col gap-4">
      <div className="flex gap-6 bg-white rounded-md p-4">
        <Icon className="stroke-accent1-900 stroke-2" size={24} name="phone" />
        {practiceNumber}
      </div>
      <div className="flex gap-6 bg-white rounded-md p-4 items-center">
        <span className="w-6">
          <Icon className="stroke-accent1-900 stroke-2" size={24} name="mail" />
        </span>
        <div className="w-[100% - 10rem] break-all">{email}</div>
        <button className="w-6 inline" onClick={() => copyInfo(email)}>
          <Icon name="copy" className="stroke-primary-main active:bg-gray-400 rounded stroke-2" />
        </button>
      </div>
    </div>
    {!hasSeenConfirmation && (
      <NavButton className="w-full" text={t('patient.checkoutPage.goToMyGuide')} path="/patient/dashboard" />
    )}
  </div>
);
