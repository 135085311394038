import { useEffect, useMemo, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Divider } from '@library/components/atoms';
import { CheckoutFinanceOptions } from '@library/components/organisms/CheckoutFinanceOptions/CheckoutFinanceOptions';
import { t } from 'i18next';

import { CheckoutPatientDecisionBlock } from '@patient/components/CheckoutPatientDecisionBlock';
import { TreatmentOverviewCard } from '@patient/components/TreatmentOverviewCard/TreatmentOverviewCard';
import { useGetCheckoutAccordionItems } from '@patient/pages/CheckoutPage/useGetAccordionItems';
import CheckoutAccordian from '@shared/components/CheckoutAccordian';
import { Spinner } from '@shared/components/Spinner';
import { Typography } from '@shared/components/Typography';
import { useGetPractice, useGetTreatmentDefinition, useGetTreatmentGuide } from '@shared/data/practice/hooks';
import {
  Accessor,
  IPaymentMethod,
  ITreatmentDefinition,
  ITreatmentGuideDecision,
  PricingType,
} from '@shared/data/types';
import { PatientMixpanel } from '@shared/utils/patientMixpanel';

export const PatientCheckout = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const treatmentGuideId = localStorage.getItem('treatmentGuideId') || '';
  const practiceId = treatmentGuideId.split(':')[0];

  const {
    data: treatmentGuide,
    isLoading: treatmentGuideIsLoading,
    refetch: refetchTreatmentGuide,
  } = useGetTreatmentGuide(treatmentGuideId, Accessor.PATIENT);
  const { data: practice, isLoading: practiceIsLoading } = useGetPractice(practiceId, Accessor.PATIENT);

  const [displayDecisionOptions, setDisplayDecisionOptions] = useState(false);
  const [selectedTerm, setSelectedTerm] = useState('');

  const treatmentGuideData = treatmentGuide?.data;

  const treatments = treatmentGuideData?.treatments;

  const financeOnly = Boolean(practice?.data.financeOnly);
  const financeEnabled = Boolean(practice?.data.offersFinance);

  const accordionItems = useGetCheckoutAccordionItems({
    treatmentGuideData,
    // Do not display finance options in accordion as per the new designs
    financeEnabled: false,
    practiceName: practice?.data.name,
  });

  const { data: treatmentDefinitions } = useGetTreatmentDefinition(undefined, Accessor.PATIENT, true);

  const treatmentNames = useMemo(
    () =>
      treatmentGuideData?.treatments
        ? treatmentGuideData.treatments.map(
            (treatment) =>
              ((treatmentDefinitions?.data as ITreatmentDefinition[]) || []).find(
                ({ id }) => id === treatment.treatmentDefinition,
              )?.name || '',
          )
        : [],
    [treatmentGuideData, treatmentDefinitions],
  );

  useEffect(() => {
    if (treatmentGuideData && treatmentGuideId && practice?.data.name) {
      if (location?.state?.signedIn) {
        PatientMixpanel.identify(treatmentGuideId, practice?.data.name);

        if (treatmentGuideData?.displayIntroduction && !location.state?.hasSeenIntroductionPage) {
          navigate('/patient/login/introduction');
        }

        if (treatmentGuideData.paymentMethod === IPaymentMethod.FINANCE) {
          navigate('/patient/dashboard/finance-confirmation');
        }
      }
    }
  }, [treatmentGuideData, practice, location.state, navigate, treatmentGuideId]);

  if (treatmentGuideIsLoading || practiceIsLoading) return <Spinner />;

  if (!treatmentGuideData) {
    return null;
  }

  if (practice && treatmentGuideData.archived) {
    return <Navigate to="/patient/archived-guide" replace />;
  }

  const displayPayInPracticeConfirmation = treatmentGuideData.paymentMethod === IPaymentMethod.PAY_IN_PRACTICE;

  const displayInterestedConfirmation =
    treatmentGuideData.pricingType !== PricingType.FINAL &&
    treatmentGuideData.decision === ITreatmentGuideDecision.INTERESTED;

  if (!practice?.data) {
    return <p>No practice</p>;
  }

  return (
    <div className="w-full max-w-[40.5rem] mx-auto gap-10 mt-12">
      <Typography variant="h3" className="mb-4">
        {t('patient.patientCheckoutPage.heading', { name: treatmentGuideData.firstName })}
      </Typography>
      <Typography variant="h6" className="mb-4">
        {`${
          (treatments?.length || 0) > 1
            ? t('patient.patientCheckoutPage.subHeadingMultiTreatments')
            : t('patient.patientCheckoutPage.subHeadingSingleTreatment')
        }`}
      </Typography>
      <div className="flex flex-col gap-4 mb-2">
        {treatments &&
          treatments.length > 0 &&
          treatments.map((treatment) => (
            <TreatmentOverviewCard key={treatment.treatmentDefinition} treatment={treatment} />
          ))}
      </div>
      <CheckoutAccordian icons={{ open: 'minus', close: 'plus' }} items={accordionItems} />
      <Divider className="mb-4" margin="medium" />
      {treatmentGuideData?.price && (
        <CheckoutFinanceOptions
          displayPricing={treatmentGuideData.displayPricing}
          price={parseInt(treatmentGuideData.price)}
          deposit={parseInt(treatmentGuideData.deposit || '0')}
          practiceName={practice.data.name}
          treatments={treatmentNames}
          selectedTerm={selectedTerm}
          setSelectedTerm={setSelectedTerm}
        />
      )}
      <CheckoutPatientDecisionBlock
        treatmentGuideId={treatmentGuideId}
        goAheadCheckedDefault={Boolean(
          !treatmentGuideData.decision ||
            (treatmentGuideData.price && treatmentGuideData.decision === ITreatmentGuideDecision.ACCEPTED),
        )}
        patientHasDeclined={treatmentGuideData.decision === ITreatmentGuideDecision.DECLINED}
        patientIsUnsure={treatmentGuideData.decision === ITreatmentGuideDecision.UNSURE}
        financeEnabled={financeEnabled}
        refetchTreatmentGuide={refetchTreatmentGuide}
        treatmentGuide={treatmentGuideData}
        practice={practice.data}
        displayFinanceConfirmation={treatmentGuideData.paymentMethod === IPaymentMethod.FINANCE}
        displayPayInPracticeConfirmation={displayPayInPracticeConfirmation}
        displayDecisionOptions={displayDecisionOptions}
        setDisplayDecisionOptions={setDisplayDecisionOptions}
        displayInterestedConfirmation={displayInterestedConfirmation}
        financeOnly={financeOnly}
        displayPricing={treatmentGuideData.displayPricing}
        enablePayment={treatmentGuideData.pricingType === PricingType.FINAL}
      />
    </div>
  );
};
