import { memo, PropsWithChildren } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { Size } from '@library/types';
import { getTextSize } from '@library/utils';
import clsx from 'clsx';

import { Icon } from '../Icon';
import { IconName } from '../Icon/utils';

export interface LinkProps extends NavLinkProps {
  text?: string;
  appearance?: 'primary' | 'secondary';
  size?: Size;
  underline?: boolean;
  className?: string;
  icon?: IconName;
}

export const Link = memo(
  ({
    className,
    text,
    appearance = 'primary',
    size = 'medium',
    underline = false,
    icon,
    ...props
  }: PropsWithChildren<LinkProps>): JSX.Element => {
    return (
      <NavLink
        {...props}
        className={clsx(
          'box-border inline-flex items-center justify-center gap-2 relative',
          underline && 'underline underline-offset-4',
          className,
          getTextSize(size),
          `${
            appearance === 'primary'
              ? 'text-backgroundBrandDefault hover:text-backgroundBrandDefaultHover active:text-backgroundBrandDefaultPressed'
              : 'text-foregroundNeutralPrimary'
          }`,
        )}
      >
        {icon && <Icon size="medium" iconName={icon} className={className} />}
        {text && text}
      </NavLink>
    );
  },
);

Link.displayName = 'Link';
